import React from 'react';
import {Box, Card, Typography} from "@material-ui/core";
import Workmark from "../../components/Workmark";


const StakeholdCard = ({title, content}) => (
  <Card>
    <Box p={3}>
      <Box display="flex" justifyContent="center" mb={2}>
        <Workmark />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography variant="h5" textAlign="center">
            {title}
          </Typography>
        </Box>
        {content}
        </Box>
      </Box>
    </Card>
)

export default StakeholdCard;
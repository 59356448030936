// material
import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
<svg viewBox="0 0 74 76" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.8848 70.8664C29.8848 72.0953 30.3729 73.2739 31.2419 74.1428C32.1109 75.0118 33.2894 75.5 34.5183 75.5H56.7153C65.7185 75.5 73.019 68.5575 73.019 59.9941C73.019 51.4307 65.7185 44.4883 56.7153 44.4883H34.5183C33.29 44.4883 32.1119 44.976 31.243 45.8443C30.3741 46.7126 29.8856 47.8903 29.8848 49.1187V70.8664Z" fill="#3167F1"/>
<path d="M43.4917 52.6485C43.4884 53.8752 42.9987 55.0506 42.1301 55.9168C41.2615 56.7831 40.0849 57.2695 38.8582 57.2695H16.6612C7.65485 57.2695 0.357422 50.327 0.357422 41.7637C0.357422 33.2003 7.65485 26.2578 16.6612 26.2578H43.4917V52.6485Z" fill="#4C7EFF"/>
<path d="M20.6289 18.7383H56.7045C65.7077 18.7383 73.0083 25.6807 73.0083 34.2441V60.0034C73.0083 51.44 65.7077 44.4976 56.7045 44.4976H25.2624C24.0335 44.4976 22.855 44.0094 21.986 43.1404C21.1171 42.2715 20.6289 41.0929 20.6289 39.864V18.7383Z" fill="#7299FF"/>
<path d="M52.7336 5.14297C52.7349 4.53369 52.6159 3.93015 52.3836 3.36689C52.1513 2.80364 51.8102 2.29173 51.3798 1.86047C50.9494 1.42921 50.4382 1.08704 49.8755 0.853597C49.3127 0.620151 48.7094 0.499999 48.1001 0.5H16.6612C7.65485 0.5 0.357422 7.44244 0.357422 16.0058V41.7651C0.357422 33.2017 7.65485 26.2593 16.6612 26.2593H48.1001C49.329 26.2593 50.5075 25.7711 51.3765 24.9022C52.2455 24.0332 52.7336 22.8547 52.7336 21.6258V5.14297Z" fill="#AFC6FF"/>
</svg>
    </Box>
  );
}

import React from 'react';
import Confetti from "../../../components/Confetti";
import {useNavigate} from "react-router-dom";
import {Box, Typography} from "@material-ui/core";
import queryString from "query-string";
import Button from "../../../components/Button";


const Congratulations = () => {
  const navigate = useNavigate();
  const { nextPath } = queryString.parse(location.search);

  const handleRedirect = () => navigate(nextPath || '/');

  return (
    <>
      <Confetti/>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Typography variant="h5" textAlign="center">
          You're accredited!
        </Typography>
        <Box mt={3}>
          <Button look="primary" onClick={handleRedirect} fullWidth>
            Continue
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Congratulations;
import React from 'react';
import * as icons from 'react-feather';
import { pascalCase } from 'change-case';

const IconRenderer = ({ iconName }: { iconName: string }) => {
  if (!iconName) return null;

  const pascalCaseName = pascalCase(iconName).split('_').join('');
  // @ts-ignore
  const Icon = icons[pascalCaseName];

  if (!Icon) return null;

  return <Icon />;
};

export default IconRenderer;

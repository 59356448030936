/* eslint-disable max-len */
import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box, Checkbox, InputAdornment, SvgIcon, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Search as SearchIcon } from 'react-feather';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    maxWidth: 500,
    flexGrow: 1,
  },
  bulkOperations: {
    position: 'relative'
  },
  displayNone: {
    display: 'none',
  },
  invisible: {
    visibility: 'hidden',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    // backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    // marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    // marginRight: theme.spacing(1)
  },
  clickable: {
    cursor: 'pointer',
  }
}));

export interface LedgerColumn {
  title?: string,
  right?: boolean,
  cellContent: React.FunctionComponent<any>,
  width?: string,
  cellProps?: any,
}

interface Props {
  columns: LedgerColumn[],
  results: {
    id: string,
  }[],
  rawResults: any,
  minWidth?: any,
  bulkActions?: any,
  isLoading?: any,
  emptyState?: any,
  actions?: any,
  setPage: any,
  handlePageChange?: any,
  handleLimitChange?: any,
  setLimit: any,
  setQuery: any,
  setSort: any,
  setFilters: any,
  query: any,
  page: any,
  limit: any,
  count?: any,
  enableBulkOperations?: boolean,
  isPaginated?: boolean,
  isSearchable?: boolean,
  isSortable?: boolean,
  searchBoxPadding?: any,
  enableSelectAll?: any,
  onRowClick?: any,
}

function Ledger({
  columns,
  results,
  rawResults,
  minWidth,
  BulkActions,
  isLoading,
  emptyState,
  actions,
  handlePageChange,
  handleLimitChange,
  setPage,
  setLimit,
  setQuery,
  setSort,
  setFilters,
  query,
  page,
  limit,
  count,
  enableBulkOperations,
  isPaginated,
  isSearchable,
  searchBoxPadding = 2,
  enableSelectAll = true,
  onRowClick,
  ...ledgerProps
}: Props) {
  const classes = useStyles();
  const [selectedResults, setSelectedResults] = useState<string[]>([]);
  const handleQueryChange = (event: any) => {
    event.persist();
    setQuery(event.target.value);
  };
  // const handleSortChange = (event: any) => {
  //   event.persist();
  //   setSort(event.target.value);
  // };
  const handleSelectAllResults = (event: any) => {
    setSelectedResults(event.target.checked
      ? results
      : []);
  };
  const handleSelectOneResult = (event: any, result: string) => {
    if (!selectedResults.includes(result)) {
      setSelectedResults((prevSelected) => [...prevSelected, result]);
    } else {
      setSelectedResults((prevSelected) => prevSelected.filter((row) => row !== result));
    }
  };
  // Usually query is done on backend with indexing solutions
  const selectedSomeResults = selectedResults.length > 0 && selectedResults.length < results.length;
  const selectedAllResults = selectedResults.length === results.length;
  const isSelected = (result: any) => selectedResults.includes(result);
  const loadingRows = ([1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
    <TableRow hover key={_}>
      <TableCell padding="checkbox">
        <Checkbox checked={false} value={false} disabled />
      </TableCell>
      {
        columns.map((__, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={i}>
            <Skeleton animation="wave" />
          </TableCell>
        ))
      }
    </TableRow>
  )));
  const rows = results.map((result) => (
    // @ts-ignore
    <TableRow
      hover
      key={result.id}
      selected={isSelected(result)}
      className={clsx({
        [classes.clickable]: Boolean(onRowClick)
      })}
      onClick={onRowClick ? () => onRowClick(result) : null}
    >
      {enableBulkOperations && (
        <TableCell padding="checkbox">
          <Checkbox checked={isSelected(result)} onChange={(event) => handleSelectOneResult(event, result)} value={isSelected(result.id)} />
        </TableCell>
      )}
      {columns.map(({ cellContent, cellProps }) => (
        <TableCell {...cellProps} key={result.id}>
          {cellContent(result, ledgerProps)}
        </TableCell>
      ))}
    </TableRow>
  ));
  const hasNoResults = !isLoading && !rows.length;

  return (
    <Box display="flex" flexDirection="column" flex="1 1">
      {(actions || isSearchable)
      && (
      <Box p={searchBoxPadding} display="flex" alignItems="flex-start" justifyContent="space-between">
        {isSearchable && (
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onChange={handleQueryChange}
          placeholder="Search results"
          value={query}
          variant="outlined"
        />
        )}
        <Box p={1} />
        <Box>
          {!hasNoResults && actions}
        </Box>
      </Box>
      )}
      {
        enableBulkOperations ? (
          <div className={clsx({
            [classes.invisible]: enableBulkOperations && !selectedResults.length,
          })}>
            <BulkActions selectedRows={selectedResults} />
          </div>
        ) : null
      }
      <Box minWidth={minWidth}>
        {hasNoResults ? emptyState : (
          <Table className={classes.table}>
            <colgroup>
              {enableBulkOperations && <col />}
              {/* eslint-disable-next-line react/no-array-index-key */}
              {columns.map(({ width }, i) => <col key={i} width={width} />)}
            </colgroup>
            {columns.filter((col) => col.title).length ? (
              <TableHead>
                <TableRow>
                  {enableBulkOperations && (
                  <TableCell padding="checkbox">
                    <Checkbox disabled={isLoading} checked={!isLoading && selectedAllResults} indeterminate={selectedSomeResults} onChange={handleSelectAllResults} />
                  </TableCell>
                  )}
                  {columns.map(({ title, cellProps }, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell {...cellProps} key={i}>
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {isLoading ? loadingRows : rows}
            </TableBody>
          </Table>
        )}
      </Box>

      {hasNoResults || !isPaginated || !rawResults.count ? null : (
        <TablePagination
          component="div"
          count={rawResults.count}
          onPageChange={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page - 1} // TablePagination is zero-indexed but DRF pagination is not
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
        />
      )}
    </Box>
  );
}
Ledger.defaultProps = {
  results: []
};
export default Ledger;

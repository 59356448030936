import React from 'react';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
  useFormikContext, FieldArray, FormikValues, FormikHelpers, FormikContextType
} from 'formik';
import { Props as ButtonProps } from 'src/components/Button';
import FormikForm from './FormikForm';

/*
Note: if you want to use an array with this component, it has to be named
you can't have a form with a straight list of items.
 */
const mergeInitialValuesWithDefaultValues = (fields: any, values = {}) => {
  let initialValues = {};
  fields.forEach((field: any) => {
    if (field.fields && field.name) {
      if (field.isArray && field.isDynamic) {
        // this key is an array with a dynamic number of items
        // @ts-ignore
        initialValues[field.name] = (values[field.name] || []).map((value: any) => mergeInitialValuesWithDefaultValues(field.fields, value));
      } else if (field.isArray) {
        //   // this key is an array with a fixed number of items
        // @ts-ignore
        initialValues[field.name] = field.fields.map((_field: any) => mergeInitialValuesWithDefaultValues([_field], {}));
      } else {
        // this key is an object
        // @ts-ignore
        initialValues[field.name] = mergeInitialValuesWithDefaultValues(field.fields, values[field.name]);
      }
    } else if (field.fields) {
      // this isn't a field, dont create it in the schema
      initialValues = {
        ...initialValues,
        ...mergeInitialValuesWithDefaultValues(field.fields, values)
      };
    } else {
      // this is a normal field, use the defaultValue from the config unless we're provided with an initialValue
      // @ts-ignore
      initialValues[field.name] = values[field.name] !== undefined ? values[field.name] : field.defaultValue;
    }
  });
  return initialValues;
};
const createValidationSchema = (fields: any) => {
  let validationObj = {};
  fields.forEach((field: any) => {
    if (field.fields && field.name) {
      if (field.isArray) {
        // @ts-ignore
        validationObj[field.name] = Yup.array().of(Yup.object().shape({
          ...createValidationSchema(field.fields)
        }));
      } else {
        // @ts-ignore
        validationObj[field.name] = Yup.object().shape({
          ...createValidationSchema(field.fields)
        });
      }
    } else if (field.fields) {
      validationObj = {
        ...validationObj,
        ...createValidationSchema(field.fields)
      };
    } else if (field.validator) {
      // @ts-ignore
      validationObj[field.name] = field.validator;
    }
  });
  return validationObj;
};
const Fields = ({ fields, noGrid, ...rest }: any) => {
  const formik = useFormikContext();
  return fields.map((field: any, index: any) => {
    const gridProps = field.gridProps || {};
    if (field.shouldRender && !field.shouldRender(formik)) return null;
    if (field.fields) {
      return <Section section={field} />;
    }
    if (field.component && !noGrid) {
      return (
        <Grid key={`${field.name}-${index}`} item xs={12} {...gridProps}>
          {field.component(rest, formik)}
        </Grid>
      );
    }
    if (field.component) {
      return field.component(rest, formik);
    }
  });
};
const Section = ({ section }: any) => {
  const formik = useFormikContext();
  if (section.shouldRender && !section.shouldRender(formik)) return null;
  const containerGridProps = section.containerProps || {};
  const gridProps = section.gridProps || {};
  let sectionComponent = <Fields fields={section.fields} noGrid={section.noFieldGrid} />;
  if (!section.noFieldGrid) {
    sectionComponent = (
      <Grid container spacing={2} {...containerGridProps}>
        {sectionComponent}
      </Grid>
    );
  }
  if (section.component) {
    sectionComponent = section.component(sectionComponent);
  }
  if (section.isArray) {
    sectionComponent = (
      <FieldArray
        name={section.name}
        render={(arrayHelpers) => {
          if (section.noFieldGrid) {
            if (section.component) {
              return section.component(<Fields noGrid fields={section.fields()} />);
            }
            return <Fields noGrid fields={section.fields()} />;
          }
          const _fields = formik.values[section.name] && formik.values[section.name].length ? formik.values[section.name] : [{}];
          let content = (
            <Grid container spacing={2} {...containerGridProps}>
              {_fields.map((_: any, index: any) => (<Fields key={index} fields={section.fields(index)} {...arrayHelpers} />))}
            </Grid>
          );
          if (section.noFieldGrid) {
            content = _fields.map((_: any, index: any) => (<Fields noGrid fields={section.fields(index)} {...arrayHelpers} />));
          }
          return section.component ? (section.component(content)) : content;
        }}
      />
    );
  }
  if (section.noGrid) return sectionComponent;
  return (
    <Grid item xs={12} {...gridProps}>
      {sectionComponent}
    </Grid>
  );
};

const toConfig = (fields: any, initialValues: any) =>
// pass args to the .field() function
  fields.map((field: any) => {
    if (field.fields && field.isArray) {
      // the key that we're looking at is an array
      const initialFields = initialValues[field.name] && initialValues[field.name].length ? initialValues[field.name] : [{}];
      let arrayFields: any = [];
      initialFields.forEach((_: any, index: any) => {
        arrayFields = [...arrayFields, ...toConfig(field.fields(index), initialFields)];
      });
      return {
        ...field,
        fields: arrayFields,
      };
    }
    if (field.fields && !field.isArray) {
      // the key that we're looking at is a dictionary
      return field;
    }
    // this is a leaf on the tree - a regular field
    return field;
  });

export interface FieldConfig {
    name?: string,
    component?: React.FunctionComponent<any>,
    validator?: any,
    defaultValue?: any,
    shouldRender?: (formik: FormikContextType<any>) => boolean,
    gridProps?: any,
    fields?: FieldConfig[] | ((index: number) => FieldConfig[]),
    isArray?: boolean,
    isDynamic?: boolean,
}

export interface Props {
    onSubmit: (payload: FormikValues, afterSubmitCallback: () => void) => void,
    primaryButton?: ButtonProps,
    secondaryButton?: ButtonProps,
    tertiaryButton?: ButtonProps,
    fieldsConfig: FieldConfig[],
    result: Record<string, any>,
    children?: React.ReactNode,
    render?: (fieldsConfig: FieldConfig[]) => React.ReactNode,
    isLoading?: boolean,
    autoSave?: boolean,
}

const FormikConfigForm = ({
  fieldsConfig, result, children, render, ...rest
}: Props) => {
  /*
    Turns a form config object into a validationSchema, initialValues, and fields
     */
  const config = toConfig(fieldsConfig, result);
  const initialValues = mergeInitialValuesWithDefaultValues(config, result);
  const validationSchema = Yup.object().shape({
    ...createValidationSchema(config)
  });
  return (
    <FormikForm {...rest} initialValues={initialValues} validationSchema={validationSchema}>
      {render ? render(fieldsConfig) : <Fields fields={fieldsConfig} />}
      {children}
    </FormikForm>
  );
};

export default FormikConfigForm;

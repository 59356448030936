import React from 'react';
import { InputAdornment, TextField as MuiTextField } from '@material-ui/core';

import CurrencyInput from './masks/CurrencyInput';
import PercentageInput from './masks/PercentageInput';
import PhoneNumberInput from './masks/PhoneNumberInput';
import SocialSecurityMask from './masks/SocialSecurityInput';
import NumbersOnlyInput from './masks/NumbersOnlyInput';
import YearInput from './masks/YearInput';

const TextField = ({
  look,
  ...props
}: any) => {
  const inputProps = {
    ...props.InputProps,
  };

  if (look === 'currency') {
    inputProps.inputComponent = CurrencyInput;
    inputProps.startAdornment = <InputAdornment position="start">$</InputAdornment>;
  } else if (look === 'phone') {
    inputProps.inputComponent = PhoneNumberInput;
  } else if (look === 'ssn') {
    inputProps.inputComponent = SocialSecurityMask;
  } else if (look === 'percentage') {
    inputProps.endAdornment = <InputAdornment position="start">%</InputAdornment>;
    inputProps.inputComponent = PercentageInput;
  } else if (look === 'year') {
    inputProps.inputComponent = YearInput;
  } else if (look === 'caseNumber') {
    inputProps.inputComponent = NumbersOnlyInput;
    inputProps.startAdornment = <InputAdornment position="start">#</InputAdornment>;
  // } else if (look === 'zipCode') {
  //   inputProps.inputComponent = ZipCodeInput;
  } else if (look === 'number') {
    inputProps.inputComponent = NumbersOnlyInput;
  }

  return (
    <MuiTextField
      variant="outlined"
      fullWidth
      {...props}
      required={false}
      InputProps={inputProps}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default TextField;

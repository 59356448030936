import { useState, useEffect } from 'react';
import StakeholdHttp from '../utils/api';

const useGetRequest = ({
  url,
  processResult,
  payload,
  onError,
  onSuccess,
  shouldFetchOnInitialRender = true,
  shouldCamelCaseResponse = true,
  initialResult = null,
  deps = [],
  defaultLoadingState = true,
  methodOverride
}: any) => {
  const [result, setResult] = useState(initialResult);
  const [isLoading, setIsLoading] = useState(defaultLoadingState);
  const httpRequest = ({ payload: payloadOverride, url: urlOverride, onSuccess: onSuccessOverride }: any = {}) => {
    const _payload = payloadOverride === undefined ? payload : payloadOverride;
    const _url = urlOverride === undefined ? url : urlOverride;
    const _onSuccess = onSuccessOverride === undefined ? onSuccess : onSuccessOverride;
    setIsLoading(true);
    const stakeholdHttp = new StakeholdHttp(_url, _payload);

    if (!shouldCamelCaseResponse) {
      stakeholdHttp.withoutCasing();
    }

    let promise;
    if (methodOverride === 'post') {
      promise = stakeholdHttp.post();
    } else {
      promise = stakeholdHttp.get();
    }

    return promise.then((res: any) => {
      const data = res.results ? res.results : res; // HACK - got this all over the place now
      const processedData = processResult ? processResult(data) : data;
      setResult(processedData);
      if (_onSuccess) _onSuccess(data);
    })
      .catch(onError)
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    let _url = url;
    // don't make request if deps are undefined
    if (typeof _url === 'function' && deps && deps.filter((dep: any) => dep).length) {
      _url = url(...deps);
    }
    if (_url && shouldFetchOnInitialRender) {
      httpRequest({ payload, url: _url, onSuccess });
    } else {
      setIsLoading(false);
    }
  }, deps);
  return {
    result, isLoading, httpRequest, setResult
  };
};
export default useGetRequest;

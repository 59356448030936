import React from 'react';
import {
  Box,
  Typography,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Layers } from 'react-feather';
import { Variant } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 75,
    height: 75,
  }
}));

interface Props {
  icon?: React.ReactNode,
  text?: string,
  textVariant?: Variant,
  children?: React.ReactNode,
  height?: React.ReactText,
  width?: React.ReactText,
}

function EmptyState({
  icon,
  text,
  textVariant,
  children,
  height = 400,
  width
}: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.root} height={height} width={width}>
      <Typography color="textSecondary">
        <SvgIcon className={classes.icon}>
          {icon}
        </SvgIcon>
      </Typography>
      {
        text !== null && (
          <Box mt={1} mb={1}>
            <Typography
              variant={textVariant}
              color="textSecondary"
            >
              {text || 'No data'}
            </Typography>
          </Box>
        )
      }
      <Typography color="textSecondary">
        {children}
      </Typography>
    </Box>
  );
}

EmptyState.defaultProps = {
  icon: <Layers />,
  textVariant: 'subtitle1',
};

export default EmptyState;

import {Alert, Box, Card, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import WireInstructionsTable from "./WireInstructionsTable";
import Label from "../../components/Label";
import Button from "../../components/Button";
import React from "react";
import StakeholdHttp from "../../utils/api";
import {useSnackbar} from "notistack";
import StakeholdCard from "./StakeholdCard";


const WireInstructionsForm = ({ offerId, refreshCommitment, wireInstruction }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitWire = () => {
    new StakeholdHttp(
      `/apps/offers/${offerId}/confirm-wire-submission/`,
      ).post().then(() => {
        enqueueSnackbar('Completed', { variant: 'success' });
        refreshCommitment();
    }).catch(() => enqueueSnackbar('Failed to confirm wire', { variant: 'error' }))
  }

  return (
    <StakeholdCard
        title="Complete your wire to finalize your investment request."
        content={
          <>
            <WireInstructionsTable wireInstruction={wireInstruction} />
              <Box mt={2} mb={2}>
                <Alert severity="warning">
                  The memo must include <Label>{wireInstruction.memo}</Label> - your investment cannot be finalized without this included
                </Alert>
              </Box>
              <FormControlLabel
                control={<Checkbox />}
                label="I have initiated the wire to the account above."
                sx={{ mb: 2 }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="I have included the code above in the wire memo."
                sx={{ mb: 2 }}
              />
              <Button look="primary" onClick={onSubmitWire}>
                Confirm wire
              </Button>
            </>
        }
      />
  )

}

export default WireInstructionsForm;
import React from 'react';
import {
  Box,
  SvgIcon
} from "@material-ui/core";
import {Typography} from "@material-ui/core/index";
import Button from "../../../components/Button";
import {getCurrentStep} from "./utils";
import { PenTool, Users, DollarSign } from 'react-feather';
import BecomeAdvisoryMember from "./BecomeAdvisoryMember";

const Block = ({ Icon, title, description }) => (
  <Box display="flex" flexDirection="column" mb={1}>
    <Box display="flex">
      <SvgIcon sx={{width: 20, height: 20}}>
        <Icon />
      </SvgIcon>
      <Box display="flex" flexDirection="column" ml={1}>
        <Typography variant="subtitle2">
          {title}
        </Typography>
        <Typography variant="caption" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {description}
        </Typography>
      </Box>
    </Box>
  </Box>
)

const STEPS = [
  {
    component: () => (
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography variant="h5" textAlign="center">
            As a member of the Stakeholder Advisory Board for Acme...
          </Typography>
        </Box>
        <Block
          Icon={PenTool}
          title="You have policy-making abilities"
          description="The Stakeholder Advisory Board controls the assets and uses majority vote to decide on the policy of the pooled investment."
        />
        <Block
          Icon={Users}
          title="You have optional quarterly meetings"
          description="Advisory Board meetings give stakeholders place to make decisions and give feedback to the company."
        />
        <Block
          Icon={DollarSign}
          title="You are able to invest as an accredited investor"
          description="Advisory Board Members qualify as accredited investors as “knowledgeable employees” and are able to invest in any company’s Stakehold. Your accredited investor status valid only on Stakehold."
        />
        <Box mt={3}>
          <Button look="primary" fullWidth>
            Continue
          </Button>
        </Box>
      </Box>
    ),
    isComplete: () => true,
    children: [
      {
        component: BecomeAdvisoryMember,
        isComplete: (accreditation) => Boolean(accreditation.advisoryBoardSectionCompletedAt),
      },
    ]
  }
]

const AdvisoryBoard = ({ handleAccreditationChange, accreditation }) => {
  const currentStep = getCurrentStep(STEPS, accreditation);

  return (
    <currentStep.component
      accreditation={accreditation}
      handleAccreditationChange={handleAccreditationChange}
    />
  )
}

export default AdvisoryBoard;

import toCamelCase from '../utils/toCamelCase';

class WebSocketService {
  // static instance = null;
  //
  // callbacks = {};
  //
  // static getInstance() {
  //   if (!WebSocketService.instance) {
  //     WebSocketService.instance = new WebSocketService();
  //   }
  //   return WebSocketService.instance;
  // }
  socketRef: WebSocket | null = null;

  callbacks: any;

  connect() {
    // const path = 'ws://localhost:8000/ws/';
    // this.socketRef = new WebSocket(path);
    // this.socketRef.onopen = () => {
    //   console.log('WebSocket open');
    // };
    // this.socketRef.onmessage = (e) => {
    //   this.socketNewMessage(e.data);
    // };
    //
    // this.socketRef.onerror = (e) => {
    //   // @ts-ignore
    //   console.log(e.message);
    // };
    // this.socketRef.onclose = () => {
    //   console.log("WebSocket closed let's reopen");
    //   this.connect();
    // };
  }

  onReceiveMessage(data: any) {
    // up to the client to implement
  }

  socketNewMessage(message: any) {
    const parsedData = toCamelCase(JSON.parse(message));
    const { command } = parsedData;
    const { data } = parsedData;
    if (this.onReceiveMessage) {
      this.onReceiveMessage(data);
    }
  }

  addCallbacks(messagesCallback: any, newMessageCallback: any) {
    this.callbacks.messages = messagesCallback;
    this.callbacks.new_message = newMessageCallback;
  }

  sendMessage(data: any) {
    try {
      // @ts-ignore
      this.socketRef.send(JSON.stringify({ ...data }));
    } catch (err) {
      console.log(err.message);
    }
  }

  state() {
    // @ts-ignore
    return this.socketRef.readyState;
  }

  waitForSocketConnection(callback: any) {
    const socket = this.socketRef;
    const recursion = this.waitForSocketConnection;
    setTimeout(
      () => {
        if (socket && socket.readyState === 1) {
          console.log('Connection is made');
          if (callback != null) {
            callback();
          }
        } else {
          console.log('wait for connection...');
          recursion(callback);
        }
      }, 1
    ); // wait 5 milisecond for the connection...
  }
}

// const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketService;

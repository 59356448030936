import React from 'react';
import { Trash2 } from 'react-feather';
import { SvgIcon } from '@material-ui/core';
import { FormikValues } from 'formik';

import CRUDForm from './CRUDForm';
import FormikConfigForm, { FieldConfig } from './FormikConfigForm';
import { Props as ButtonProps } from '../Button';

export interface CRUDFormProps {
    url: string,
    initialValuesOverride?: FormikValues,
    beforeSubmit?: () => void,
    onSuccess?: (res: any) => void,
    onFetchSuccess?: (res: any) => void,
    onFetchError?: (err?: any) => void,
    onError?: () => void,
    afterSubmit?: () => void,
    objectId?: string,
    submitMethodOverride?: 'post' | 'patch',
    children?: React.ReactNode,

    // for the config form
    autoSave?: boolean,
    includeDeleteButton?: boolean,
    onSubmitOverride?: () => void,
    onDeleteOverride?: () => void,
    fieldsConfig: FieldConfig[],
    primaryButton?: ButtonProps,
    secondaryButton?: ButtonProps,
    tertiaryButton?: ButtonProps,
}

const CRUDConfigForm = ({
  url,
  initialValuesOverride = {},
  beforeSubmit,
  onSuccess,
  onFetchSuccess,
  onFetchError,
  onError,
  afterSubmit,
  objectId,
  submitMethodOverride,
  includeDeleteButton,
  children,
  tertiaryButton,
  onSubmitOverride,
  onDeleteOverride,
  ...rest
}: CRUDFormProps) => (
  <CRUDForm
    url={url}
    initialValuesOverride={initialValuesOverride}
    onSuccess={onSuccess}
    onFetchSuccess={onFetchSuccess}
    onFetchError={onFetchError}
    afterSubmit={afterSubmit}
    objectId={objectId}
    submitMethodOverride={submitMethodOverride}
    render={
        ({
          result,
          onSubmit,
          onDelete,
          isLoading,
        }: {
            result: FormikValues,
            onSubmit: (payload: FormikValues, formActions: any) => void,
            onDelete: (formActions: any) => any,
            isLoading: boolean,
        }) => (
          <FormikConfigForm
            {...rest}
            result={result}
            onSubmit={onSubmitOverride || onSubmit}
            isLoading={isLoading}
            // eslint-disable-next-line no-mixed-operators,max-len
            tertiaryButton={tertiaryButton || (onDeleteOverride || (includeDeleteButton && Boolean(objectId))) && {
              label: (
                <SvgIcon fontSize="small">
                  <Trash2 />
                </SvgIcon>
              ),
              onClick: onDeleteOverride || onDelete,
            } || undefined}
          >
            {children}
          </FormikConfigForm>
        )
      }
  />
);

// CRUDConfigForm.defaultProps = {
//   primaryButton: {
//     label: 'Submit'
//   },
//   xs: 12,
//   md: 12,
// };

export default CRUDConfigForm;

import React  from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import {Box, Step, Stepper, Container, StepLabel, StepConnector, Card, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// redux
import {getCurrentStep} from "./utils";
import AdvisoryBoard from "./AdvisoryBoard";
import Quiz from "./Quiz";
import Button from "../../../components/Button";
import useAuth from "../../../hooks/useAuth";
import StartupInvesting from "./StartupInvesting";
import StakeholdFunds from "./StakeholdFunds";
// routes

// ----------------------------------------------------------------------

const STEPS = [
  {
    title: 'Startup investing',
    component: StartupInvesting,
    isComplete: (accreditation) => Boolean(accreditation.startupInvestingSectionCompletedAt),
  },
  {
    title: 'Stakehold funds',
    component: StakeholdFunds,
    isComplete: (accreditation) => Boolean(accreditation.stakeholdFundsSectionCompletedAt),
  },
  {
    title: 'Advisory board',
    component: AdvisoryBoard,
    isComplete: (accreditation) => Boolean(accreditation.advisoryBoardSectionCompletedAt),
  },
  {
    title: 'Quiz',
    component: Quiz,
    isComplete: (accreditation) => Boolean(accreditation.quizSectionCompletedAt),
  },
]

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 8,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)'
  },
  active: {
    '& $line': { borderColor: theme.palette.primary.main }
  },
  completed: {
    '& $line': { borderColor: theme.palette.primary.main }
  },
  line: {
    borderTopWidth: 2,
    borderColor: theme.palette.divider
  }
}))(StepConnector);

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

function QontoStepIcon({ active, completed }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'divider',
        bgcolor: 'background.default'
      }}
    >
      {completed ? (
        <Box component={Icon} icon={checkmarkFill} sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }} />
      ) : (
        <Box
          sx={{
            width: 16,
            height: 16,
            borderRadius: '50%',
            backgroundColor: 'currentColor'
          }}
        />
      )}
    </Box>
  );
}

export default function Coursework({ accreditation, handleAccreditationChange }) {
  const currentStep = getCurrentStep(STEPS, accreditation);
  const index = STEPS.findIndex(element => element.title === currentStep.title)

  return (
    <>
      <Stepper alternativeLabel activeStep={index} connector={<QontoConnector />}>
        {STEPS.map(({ title }) => (
          <Step key={title}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{
                '& .MuiStepLabel-label': {
                  typography: 'subtitle2',
                  color: 'text.disabled'
                }
              }}
            >
              {title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={3}>
        {
          currentStep ? (
            <currentStep.component
              accreditation={accreditation}
              handleAccreditationChange={handleAccreditationChange}
            />
          ): <div>donezo</div>
        }
      </Box>
  </>
  );
}

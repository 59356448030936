import React from 'react';
import clsx from 'clsx';
import { Button as MuiButton, ButtonProps, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RefreshCw, } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  primary: {
    // backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      // backgroundColor: theme.palette.secondary.dark
    },
    // color: theme.palette.common.white,
  },
  secondary: {
    // color: theme.palette.common.white,
  },
  default: {},
  error: {
    // backgroundColor: theme.palette.error.main,
    '&:hover': {
      // backgroundColor: theme.palette.error.dark
    },
    // color: theme.palette.common.white,
  },
  loader: {
    animation: '$spin 2s infinite linear'
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    }
  },
  noPadding: {
    padding: 0,
  }
}));

export interface Props extends ButtonProps {
    look?: "primary" | "secondary" | "error" | "text" | "default" | "grey",
    isLoading?: boolean,
    component?: any,
    to?: any,
    href?: any,
    label?: string | React.ReactNode,
    noPadding?: boolean
}

interface ButtonPropsType {
    className?: string,
    variant?: "text" | "contained" | "outlined",
}

function Button({
  look = 'default', children, isLoading, disabled, className, label, noPadding, ...props
}: Props) {
  const classes = useStyles();
  const lookToButtonProps: Record<"primary" | "secondary" | "error" | "text" | "default" | "grey", ButtonPropsType> = {
    primary: {
      className: classes.primary,
      variant: 'contained',
    },
    secondary: {
      className: classes.secondary,
      variant: 'contained',
    },
    error: {
      className: classes.error,
      variant: 'contained',
    },
    text: {
      variant: undefined,
      className: undefined,
    },
    default: {
      variant: 'outlined',
      className: undefined,
    },
    grey: {
      variant: 'contained',
      className: undefined,
    },
  };

  const combinedClassName = clsx(
    lookToButtonProps[look].className, className,
    {
      [classes.noPadding]: noPadding,
    }
  );

  return (
    <MuiButton
      {...lookToButtonProps[look]}
      {...props}
      className={combinedClassName}
      disabled={disabled || isLoading}
    >
      {
          isLoading ? (
            <SvgIcon fontSize="small" className={classes.loader}>
              <RefreshCw />
            </SvgIcon>
          ) : label || children
      }
    </MuiButton>
  );
}

export default Button;

import React from 'react';
// routes
import dollarSign from '@iconify/icons-feather/dollar-sign';
import mail from '@iconify/icons-feather/mail';
import users from '@iconify/icons-feather/users';
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';

export const entitySidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'offers',
        path: PATH_DASHBOARD.general.offers,
        icon: dollarSign
      },
      { title: 'stakeholders', path: PATH_DASHBOARD.general.stakeholders, icon: users },
    ].map((item) => ({
      ...item,
      icon: <Icon icon={item.icon} width="100%" height="100%" />
    }))
  },
];

export const userSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // {
      //   title: 'invites',
      //   path: PATH_DASHBOARD.general.invites,
      //   icon: mail
      // },
      { title: 'commitments', path: PATH_DASHBOARD.general.commitments, icon: dollarSign },
    ].map((item) => ({
      ...item,
      icon: <Icon icon={item.icon} width="100%" height="100%" />
    }))
  },
];

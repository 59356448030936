import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography, Box
} from '@material-ui/core';

import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { MBreadcrumbs } from './@material-extend';

interface Props {
    title: string,
    actions: React.ReactNode,
    breadcrumbs: Array<{
        title: string,
        route: string,
    }>,
}

function Header({ breadcrumbs, title, actions }: Props) {
  const crumbs = breadcrumbs.length ? (
    <MBreadcrumbs
      links={breadcrumbs.map(({ title, route }) => ({
        name: title,
        href: route,
      })).concat([{ name: title }])}
    />
  ) : null;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h4" color="textPrimary">
          {title}
        </Typography>
        {crumbs}
      </Box>
      <Box>
        {actions}
      </Box>
    </Box>
  );
}
Header.defaultProps = {
  breadcrumbs: [],
};
export default Header;

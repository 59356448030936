import React from 'react';
import {
  Box,
  Card,
} from "@material-ui/core";
import Content from "../../components/Content";
import useGetRequest from "../../hooks/useGetRequest";
import {useNavigate, useParams} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import {bigCurrency, currency, percent} from "../../utils/numbers";
import {SAFE} from "../constants";
import WireInstructionsForm from "./WireInstructionsForm";
import StakeholdCard from "./StakeholdCard";

const Meow = ({ title, value }) => (
  <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="h3">{value}</Typography>
    </Box>
  </Card>
);

const ViewOfferForParticipant = () => {
  const { offerId } = useParams();
  const { result, isLoading: offerLoading, httpRequest: refreshCommitment } = useGetRequest({
    url: `/apps/offers/${offerId}/for-current-user/`,
  });

  if (offerLoading || !result) return null;
  const { offer, offerParticipant } = result;

  const details = (
    <>
      <Grid item xs={3}>
        <Meow title="Asset class" value={offer.assetClass.name} />
      </Grid>
      {
        offer.assetClass.assetType === SAFE ? (
          <>
            <Grid item md={3}>
              <Meow title="Valuation cap" value={bigCurrency(offer.assetClass.valuationCap)} />
            </Grid>
            <Grid item md={3}>
              <Meow title="Valuation discount" value={percent(offer.assetClass.valuationDiscount)} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={3}>
              <Meow title="Price per share" value={currency(offer.assetClass.valuationCap)} />
            </Grid>
          </>
        )
      }
      <Grid item md={3}>
        <Meow title="My commitment" value={bigCurrency(offerParticipant.amount)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <StakeholdCard
          title="Thank you!"
          content={
            <>
              <Typography sx={{mb: 2}} textAlign="center">
                Your application to invest has been submitted to {offer.issuerName}.
              </Typography>
              <Typography textAlign="center">
                Once we confirm the cash in the account, {offer.issuerName} will have the opportunity to review your allocation. If your allocation is accepted, reduced, or removed you will receive a notification.
              </Typography>
            </>
          }
        />
      </Grid>
    </>
  )

  return (
    <Content title={`${offer.issuerName}'s ${offer.name}`} maxWidth="lg" breadcrumbs={[
      {
        title: 'Commitments',
        route: '/commitments',
      },
    ]}>
    <Grid container spacing={3} justifyContent="center">
      {
        offerParticipant.status === 'FUNDS_SENT' ? (
          details
        ) : <Grid item xs={12} md={5}><WireInstructionsForm offerId={offerId} refreshCommitment={refreshCommitment} wireInstruction={offerParticipant.wireInstruction} /></Grid>
      }
    </Grid>
    </Content>
  )
};

export default ViewOfferForParticipant;
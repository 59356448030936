import React from 'react';
import {bigCurrency, percent} from "../../utils/numbers";
import HttpLedger from "../../components/HttpLedger";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import ActionButton from "../../components/ActionButton";
import {MoreHorizontal, PlusCircle} from "react-feather";
import useModal from "../../components/modal/useModal";
import OfferingInviteLinkModal from "./OfferingInviteLinkModal";
import {SvgIcon, Switch} from "@material-ui/core";
import Code from "../../components/Code";
import {useSnackbar} from "notistack";
import StakeholdHttp from "../../utils/api";
import OfferingModal from "./OfferingModal";
import Ledger from "../../components/Ledger";

const OfferingInviteLinkLedger = ({ offerId }) => {
  const {
    isOpen: isInviteStakeholdersModalOpen,
    openModal: openInviteStakeholdersModal,
    closeModal: closeStakeholdersModal,
    modalContent,
  } = useModal();
  const [isActiveById, setIsActiveById] = React.useState({});

  const { enqueueSnackbar } = useSnackbar();

  const actions = (
    <ActionButton
      icon={<PlusCircle />}
      onClick={() => openInviteStakeholdersModal()}
      look="primary"
    >
      Create invite link
    </ActionButton>
  );

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success'
    });
  }

  const toggleIsActive = (inviteLinkId, isActive) => {
    setIsActiveById({
      ...isActiveById,
      [inviteLinkId]: isActive,
    });

    new StakeholdHttp(`/apps/offer-invite-links/${inviteLinkId}/update-status/`, {isActive}).patch().then(() => {
      enqueueSnackbar('Link status updated', {
        variant: 'success'
      });
    }).catch(() => {
      enqueueSnackbar('Failed to update link status', {
        variant: 'error'
      });
      setIsActiveById({
        ...isActiveById,
        [inviteLinkId]: !isActive,
      });
    })
  }

  const columns = [
    {
      title: 'Link URL',
      cellContent: (inviteLink: any) => <Code text={inviteLink.url} onClick={() => onCopy(inviteLink.url)} />,
    },
    {
      title: 'Status',
      cellContent: (inviteLink: any) => (
        <Switch
          checked={isActiveById[inviteLink.id] === undefined ? inviteLink.isActive : isActiveById[inviteLink.id]}
          onChange={
            (e) => toggleIsActive(inviteLink.id, e.target.checked)}
        />
      ),
    },
    {
      title: 'Name',
      cellContent: (inviteLink: any) => inviteLink.name,
    },
    {
      title: 'Minimum',
      cellContent: (inviteLink: any) => bigCurrency(inviteLink.minInvestment),
    },
    {
      title: 'Maximum',
      cellContent: (inviteLink: any) => bigCurrency(inviteLink.maxInvestment),
    },
    {
      cellContent: (inviteLink: any) => (
        <Button variant="text" onClick={() => openInviteStakeholdersModal(inviteLink.id)}>
          <SvgIcon
            fontSize="small"
          >
            <MoreHorizontal />
          </SvgIcon>
        </Button>
      ),
      width: '50px'
    }
  ];



  return (
      <HttpLedger
        url={`/apps/offers/${offerId}/invite-links/`}
        columns={columns}
        emptyState={
          <EmptyState text="Invite your stakeholders">
            <Button look="primary" onClick={() => openInviteStakeholdersModal()}>
              Create invite link
            </Button>
          </EmptyState>
        }
        actions={actions}
        render={({
            fetchData,
            ...ledgerProps
          }: any) => (
            <>
              {
                isInviteStakeholdersModalOpen && (
                  <OfferingInviteLinkModal
                    onClose={closeStakeholdersModal}
                    fetchData={fetchData}
                    offerId={offerId}
                    offerInviteLinkId={modalContent}
                  />
                )
              }
              <Ledger {...ledgerProps} />
            </>
          )}
      />
  )
};

export default OfferingInviteLinkLedger;
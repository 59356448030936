import React from 'react';
import {bigCurrency, percent} from "../../utils/numbers";
import HttpLedger from "../../components/HttpLedger";
import EmptyState from "../../components/EmptyState";
import Content from "../../components/Content";
import Label from "../../components/Label";
import {useNavigate} from "react-router-dom";
import OfferParticipantStatus from "./OfferParticipantStatus";

const ViewCommitmentsForCurrentUser = () => {
  const columns = [
    {
      title: 'Issuer',
      cellContent: (participant: any) => participant.issuerName,
    },
    {
      title: 'Offer',
      cellContent: (participant: any) => participant.offerName,
    },
    {
      title: 'Status',
      cellContent: (participant: any) => <OfferParticipantStatus status={participant.status} />
    },
    {
      title: 'Commitment',
      cellContent: (participant: any) => bigCurrency(participant.amount),
    },
  ];
  const navigate = useNavigate();

  const emptyState = (
    <EmptyState text="Your commitments will appear here" />
  );
  return (
    <Content title="My commitments" maxWidth="lg">
      <HttpLedger
        url={`/apps/commitments/for-current-user/`}
        columns={columns}
        emptyState={emptyState}
        onRowClick={row => navigate(`/offers/${row.offerId}`)}
      />
    </Content>
  );
};

export default ViewCommitmentsForCurrentUser;

import React from 'react';

import BaseModal from 'src/components/modal/BaseModal';
import FormikInput from "../../components/formik/FormikInput";
import * as Yup from "yup";
import CRUDConfigForm from "../../components/formik/CRUDConfigForm";

interface Props {
  onClose: () => void,
  fetchData: () => void,
  offerId: string,
  offerInviteLinkId?: string,
}

function OfferingInviteLinkModal({
  onClose,
  fetchData,
  offerId,
  offerInviteLinkId,
}: Props) {
  const onSuccess = () => {
    onClose();
    fetchData();
  }
  const fieldsConfig = [
    {
      name: 'name',
      component: () => (
        <FormikInput
          fullWidth
          label="Name"
          name="name"
        />
      ),
      validator: Yup.string().required('This field is required.'),
    },
    {
      name: 'minInvestment',
      component: () => (
        <FormikInput
          fullWidth
          label="Minimum investment"
          name="minInvestment"
          look="currency"
        />
      ),
      gridProps: { xs: 12, md: 6 },
    },
    {
      name: 'maxInvestment',
      component: () => (
        <FormikInput
          fullWidth
          label="Maximum investment"
          name="maxInvestment"
          look="currency"
        />
      ),
      gridProps: { xs: 12, md: 6 },
    },
  ];


  return (
    <BaseModal
      onClose={onClose}
      title={`${offerInviteLinkId ? 'Edit' : 'Create'} invite link`}
      isOpen
    >
      <CRUDConfigForm
        url={`/apps/offers/${offerId}/invite-links/`}
        onSuccess={onSuccess}
        fieldsConfig={fieldsConfig}
        objectId={offerInviteLinkId}
        includeDeleteButton
      />
    </BaseModal>
  );
}

export default OfferingInviteLinkModal;

import React from 'react';
import {Box, Card} from "@material-ui/core";
import Content from "../../components/Content";
import useGetRequest from "../../hooks/useGetRequest";
import {useParams} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import {bigCurrency, currency, percent} from "../../utils/numbers";
import {SAFE} from "../constants";
import useModal from "../../components/modal/useModal";
import OfferingInviteLinkLedger from "./OfferingInviteLinkLedger";

const Meow = ({ title, value }) => (
  <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="h3">{value}</Typography>
      </Box>
    </Card>
);

const Offering = () => {
  const { offerId } = useParams();
  const { result: offer, isLoading } = useGetRequest({
    url: `/apps/offers/${offerId}/`,
  });

  const {
    isOpen: isInviteStakeholdersModalOpen,
    openModal: openInviteStakeholdersModal,
    closeModal: closeStakeholdersModal,
  } = useModal();

  if (isLoading || !offer) return null;

  return (
    <Content title={offer.name} maxWidth="lg" breadcrumbs={[
      {
        title: 'Offerings',
        route: `/entities/${offer.issuerId}/offers`,
      },
    ]}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Meow title="Asset class" value={offer.assetClass.name} />
        </Grid>
        {
          offer.assetClass.assetType === SAFE ? (
            <>
              <Grid item xs={4}>
                <Meow title="Valuation cap" value={bigCurrency(offer.assetClass.valuationCap)} />
              </Grid>
              <Grid item xs={4}>
                <Meow title="Valuation discount" value={percent(offer.assetClass.valuationDiscount)} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <Meow title="Price per share" value={currency(offer.assetClass.valuationCap)} />
              </Grid>
            </>
          )
        }
      </Grid>
      <OfferingInviteLinkLedger offerId={offerId} />
    </Content>
  )
};

export default Offering;
import { noCase } from 'change-case';
import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar, colors, SvgIcon
} from '@material-ui/core';
// utils
import { useSelector } from 'react-redux';
// components
import WebSocketService from 'src/services/webSocket';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MAvatar, MIconButton } from '../../components/@material-extend';
import StakeholdHttp from '../../utils/api';
import * as api from '../../utils/api';
import IconRenderer from '../../components/IconRenderer';

// ----------------------------------------------------------------------

type TNotificationPopover = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
};

function renderContent(notification: TNotificationPopover) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title
  };
}

function NotificationItem({ notification }: { notification: any }) {
  const {
    icon,
    getStatement,
    eventTitle,
  } = {}[notification.event.eventType];

  const title = (
    <Typography variant="subtitle2">
      {eventTitle}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {getStatement(notification.event)}
      </Typography>
    </Typography>
  );

  return (
    <ListItem
      button
      to={`/engagements/${notification.event.engagementId}`}
      disableGutters
      key={notification.id}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <MAvatar color="info">
          <SvgIcon fontSize="small">
            <IconRenderer iconName={icon} />
          </SvgIcon>
        </MAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={(
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification.event.createdDatetime))}
          </Typography>
        )}
      />
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [result, setResult] = useState({
    results: []
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const fetchNotifications = () => {
    const socket = new WebSocketService();
    socket.onReceiveMessage = (data) => {
      // @ts-ignore - paginated response
      setResult((_result) => ({
        ...result,
        results: [data, ..._result.results] // todo - why does this need to be paginated?
      }));
      setNotificationCount((_count) => _count + 1);
    };
    socket.connect();
    // Add callbacks to websocket instance to handle notifications
    new StakeholdHttp('/automatic/notifications/').get()
      .then((result) => {
        setResult(result);
        const count = result.results.filter((notification: any) => !notification.readDatetime).length;
        setNotificationCount(count);
      })
      .catch(() => { });
  };
  const handleOpen = () => {
    setOpen(true);
    api.readNotifications();
  };
  const handleClose = () => {
    setOpen(false);
    setNotificationCount(0);
  };

  React.useEffect(fetchNotifications, []);
  const notifications = result.results || [];

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? 'primary' : 'default'}
      >
        <Badge badgeContent={notificationCount} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{
          display: 'flex', alignItems: 'center', py: 2, px: 2.5
        }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {notificationCount} unread messages
            </Typography>
          </Box>

          {notificationCount > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary">
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import React from 'react';
import {
  Dialog,
  Card,
  CardContent,
  CardHeader,
  Box,
  Divider,
  Zoom,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ModalSize } from 'src/types';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '90vh',
  },
  dialogCard: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  scroll: {
    display: 'flex',
    flexGrow: 1,
  }
}));

// @ts-ignore
const Transition = React.forwardRef((props, ref) => <Zoom ref={ref} {...props} />);

export interface Props {
  isOpen?: boolean,
  onClose?: () => void,
  children: React.ReactNode,
  title: string,
  action?: React.ReactNode,
  maxWidth?: ModalSize,
  fullHeight?: boolean,
  fullWidth?: boolean,
  boxProps?: any,
  dialogProps?: any,
  disableTransition?: boolean,
  hideBackdrop?: boolean,
}

/*
Control the isOpen manually here
 */
function BaseModal({
  onClose,
  children,
  title,
  action,
  maxWidth,
  fullHeight,
  fullWidth,
  boxProps,
  dialogProps,
  disableTransition,
  hideBackdrop,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog
      classes={((fullHeight || maxWidth === 'xl') && { paper: classes.dialogPaper }) || null}
      maxWidth={(fullWidth || maxWidth === 'xl') ? null : maxWidth || 'sm'}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      TransitionComponent={disableTransition ? undefined : Transition}
      open
      keepMounted
      fullWidth
      {...dialogProps}
    >
      <Card className={classes.dialogCard}>
        <CardHeader title={(
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              {title}
            </Box>
            <Box>
              {action}
            </Box>
          </Box>
        )}
        />
        <PerfectScrollbar options={{ suppressScrollX: true }} className={classes.scroll}>
          <CardContent className={classes.dialogCard}>
            <Box p={2} {...boxProps}>
              {children}
            </Box>
          </CardContent>
        </PerfectScrollbar>
      </Card>
    </Dialog>
  );
}

BaseModal.defaultProps = {
  onClose: () => {},
  open: false,
};

export default BaseModal;

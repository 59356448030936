// @ts-ignore
import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';


const ACCESS_TOKEN_NAME = 'accessToken';
const EMAIL_NAME = 'email';

class AuthService {
  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken: any) => {
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_NAME);

  isValidToken = (accessToken: any) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  setEmail = (email: any) => {
    localStorage.setItem(EMAIL_NAME, email);
  }

  removeEmail = () => {
    localStorage.removeItem(EMAIL_NAME);
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;

import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import * as api from "../utils/api";

// ----------------------------------------------------------------------
const ACCESS_TOKEN_NAME = 'accessToken';
const EMAIL_NAME = 'email';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Update = 'UPDATE'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.Update]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        }
      };
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN_NAME);

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = await api.getCurrentUser();

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    return api.logInWithUsernamePassword({
      email,
      password
    }).then(user => {
      setSession(user.access);
      dispatch({
        type: Types.Login,
        payload: {
          user
        }
      });
      return user;
    })
  };

  const updateUser = payload => {
    dispatch({
      type: Types.Update,
      payload,
    });
  }

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    return api.signUp({
      email,
      password,
      firstName,
      lastName
    }).then(user => {
      window.localStorage.setItem(ACCESS_TOKEN_NAME, user.access);
      dispatch({
        type: Types.Register,
        payload: {
          user
        }
      });
    })
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const sendResetPasswordEmail = (email: string) => {
    return api.sendResetPasswordEmail({ email });
  };

  const confirmResetPassword = (payload: any) => {
    return api.confirmResetPassword(payload).then(user => {
      setSession(user.access);
      dispatch({
        type: Types.Login,
        payload: {
          user
        }
      });
    })
  };

  const sendEmailVerificationEmail = (email: string) => {
    return api.sendEmailVerificationEmail({ email });
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        sendResetPasswordEmail,
        updateUser,
        sendEmailVerificationEmail,
        confirmResetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

import React from "react";
import {Box, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import Button from "../../../components/Button";
import StakeholdHttp from "../../../utils/api";


const StakeholdFunds = ({handleAccreditationChange}) => {
  const markStakeholdFundsSectionCompleted = () => {
    new StakeholdHttp('/apps/accreditations/mark-section-as-complete/STAKEHOLD_FUNDS/', ).patch().then(res => {
      handleAccreditationChange(res)
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mb={1}>
        <Typography variant="h5" textAlign="center">
          Understanding stakehold funds.
        </Typography>
      </Box>
      <iframe
        style={{
          width: '100%',
          height: '100%',
        }}
        src="https://www.youtube.com/embed/Y3AM00DH0Zo"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <Box mt={3}>
        <Button look="primary" fullWidth onClick={markStakeholdFundsSectionCompleted}>
          Continue
        </Button>
      </Box>
    </Box>
  )
};

export default StakeholdFunds;

import numeral from 'numeral';

export const currency = (number: any) => numeral(number).format('$0,0[.]00');

export const bigCurrency = (number: any) => numeral(number).format('$0,0');

// @ts-ignore
export const percent = (number: any, precision = 2) => numeral(number).format(`0.${precision * '0'}%`);

export const integer = (number: any) => numeral(number).format('0,0');

import React from "react";
import {Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import Button from "../../../components/Button";
import StakeholdHttp from "../../../utils/api";


const BecomeAdvisoryMember = ({handleAccreditationChange}) => {
  const markAdvisoryBoardSectionCompleted = () => {
    new StakeholdHttp('/apps/accreditations/mark-section-as-complete/ADVISORY_BOARD/', ).patch().then(res => {
      handleAccreditationChange(res)
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mb={3}>
        <Typography variant="h5" textAlign="center">
          Become a Stakeholder Advisory Board Member.
        </Typography>
      </Box>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree to the Stakeholder Advisory Board Agreement and any amended agreement."
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="As a Stakeholder Advisory Board Member, I am a “knowledgeable employee” of the pooled investment vehicle under the definition in Rule 3c–5(a)(4)."
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="I acknowledge that I will be removed from the Advisory Board if I do not make an investment within 30 days."
        sx={{ mb: 2 }}
      />
      <Box mt={3}>
        <Button look="primary" fullWidth onClick={markAdvisoryBoardSectionCompleted}>
          Agree and submit
        </Button>
      </Box>
    </Box>
  )
};

export default BecomeAdvisoryMember;

import React from "react";
import {Box, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import Button from "../../../components/Button";
import StakeholdHttp from "../../../utils/api";


const Attestation = ({handleAccreditationChange}) => {
  const [attestation, handleAttestationChange] = React.useState();
  const updateAttestation = () => {
    new StakeholdHttp('/apps/accreditations/update-attestation/', { attestation }).patch().then(res => {
      handleAccreditationChange(res);
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mb={3}>
        <Typography variant="h5" textAlign="center">
          What best describes you?
        </Typography>
      </Box>
        <RadioGroup
          name="attestation"
          value={attestation}
          onChange={e => handleAttestationChange(e.target.value)}
        >
          <FormControlLabel sx={{ marginBottom: 2 }} value="ASSETS" control={<Radio />} label="I have more than $1M (or equivalent) in net assets" />
          <FormControlLabel sx={{ marginBottom: 2 }} value="INCOME" control={<Radio />} label="I have income of $200k (or $200k jointly with spouse) for the past two years and expect the same this year" />
          <FormControlLabel sx={{ marginBottom: 2 }} value="CERTIFICATION" control={<Radio />} label="I am a Series 7, Series 65, or Series 82 holder and my license is active and in good standing" />
          <FormControlLabel sx={{ marginBottom: 4 }} value="NOT_ACCREDITED" control={<Radio />} label="None of the above" />
        </RadioGroup>
      <Button look="primary" onClick={updateAttestation}>
        Continue
      </Button>
    </Box>
  )
};

export default Attestation;

import React from 'react';
import {Box, Card, Grid} from "@material-ui/core";
import Coursework from "./Coursework";
import Workmark from "../../../components/Workmark";
import {getCurrentStep} from "./utils";
import useAuth from "../../../hooks/useAuth";
import Attestation from "./Attestation";
import InvestmentExperienceLevel from "./InvestmentExperienceLevel";
import Congratulations from "./Congratulations";
import SomethingWentWrong from "./SomethingWentWrong";

const STEPS = [
  {
    component: Attestation,
    isComplete: (accreditation) => Boolean(accreditation.attestation),
    children: [
      {
        component: InvestmentExperienceLevel,
        isComplete: (accreditation) => Boolean(accreditation.investmentExperienceLevel),
      },
      {
        component: Coursework,
        isComplete: (accreditation) => Boolean(accreditation.quizSectionCompletedAt),
      },
    ],
  },
  {
    component: SomethingWentWrong,
    isComplete: (accreditation) => Boolean(accreditation.accreditedAt)
  },
  {
    component: Congratulations,
    isComplete: () => false
  }
]

const GetAccredited = () => {
  const { user, updateUser } = useAuth();
  const accreditation = user.accreditation;
  const handleAccreditationChange = updates => updateUser({
    accreditation: {
      ...accreditation,
      ...updates
    }
  });

  const currentStep = getCurrentStep(STEPS, accreditation);
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={4}>
        <Card>
          <Box p={3}>
            <Box display="flex" justifyContent="center" mb={2}>
              <Workmark />
            </Box>
            { currentStep ? <currentStep.component accreditation={accreditation} handleAccreditationChange={handleAccreditationChange} />: <div>You're accredited!</div>}
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default GetAccredited;

import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {Typography} from "@material-ui/core/index";
import Button from "../../../components/Button";
import {getCurrentStep} from "./utils";
import useGetRequest from "../../../hooks/useGetRequest";
import StakeholdHttp from "../../../utils/api";

const Block = ({ question, index, count, refreshQuizQuestions, handleAccreditationChange }) => {
  const [optionId, handleOptionIdChange] = React.useState(question.answerOptionId);
  const markStakeholdFundsSectionCompleted = () => {
    new StakeholdHttp(`/apps/accreditations/quiz-questions/${question.id}/answer/`, { optionId }).patch().then(res => {
      if (res.accreditedAt) {
        handleAccreditationChange(res)
      }
      refreshQuizQuestions();
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" justifyContent="center" mb={3}>
        <Typography variant="h5" textAlign="center">
          Let's Find Out What Kind Of Pancake You Are
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} textAlign="center">
          {`(${index} of ${count})`}
        </Typography>
      </Box>
      <Typography variant="subtitle2">
        {question.text}
      </Typography>
      <Box mt={1}>
        <RadioGroup
          value={optionId}
          onChange={e => handleOptionIdChange(e.target.value)}
        >
          {
            question.options.map(({id, text}) => (
              <FormControlLabel
                value={id} control={<Radio />} label={text}
              />
            ))
          }
        </RadioGroup>
      </Box>
      <Box mt={3}>
        <Button look="primary" fullWidth onClick={markStakeholdFundsSectionCompleted}>
          Continue
        </Button>
      </Box>
    </Box>
  )
}

const GetAccredited = ({accreditation, handleAccreditationChange}) => {
  const { result: questions, isLoading, httpRequest } = useGetRequest({
    url: '/apps/accreditations/quiz-questions/',
    initialResult: [],
  });
  const steps = questions.map((question, index) => ({
    component: () => (
      <Block
        question={question}
        index={index + 1}
        count={questions.length}
        refreshQuizQuestions={httpRequest}
        handleAccreditationChange={handleAccreditationChange}
      />
    ),
    isComplete: () => question.answerOptionId,
  }));

  const currentStep = getCurrentStep(steps, accreditation);

  if (isLoading) return null;

  return (
    <currentStep.component
      accreditation={accreditation}
      handleAccreditationChange={handleAccreditationChange}
    />
  )
}

export default GetAccredited;

import React from 'react';
import EmptyState from './EmptyState';

type State = any;
class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <EmptyState text={this.props.errorText || 'Something went wrong.'} />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;

export const SAFE = "SAFE"
export const MEMBERSHIP_INTEREST = "MEMBERSHIP_INTEREST"
export const SHARES = "SHARES"

export const DEFAULT_STAKEHOLD_ASSET_CLASS_NAME = 'Membership interest'

export const ASSET_CLASS_TYPE_BY_LABEL = {
  [SAFE]: 'SAFE',
  [SHARES]: 'Shares',
}

export const ASSET_CLASS_TYPE_OPTIONS = Object.keys(ASSET_CLASS_TYPE_BY_LABEL).map(key => ({
  label: ASSET_CLASS_TYPE_BY_LABEL[key],
  value: key,
}));

import React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Typography} from "@material-ui/core";
import Button from "../../../components/Button";


const SomethingWentWrong = () => {
  const navigate = useNavigate();

  const handleRedirect = () => navigate('/');

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="h5" textAlign="center">
        Something went wrong.
      </Typography>
      <Typography variant="body1" textAlign="center" sx={{mt:1}}>
        There was a problem getting you accredited. Please contact support.
      </Typography>
      <Box mt={3}>
        <Button look="primary" onClick={handleRedirect} fullWidth>
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default SomethingWentWrong;
import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Box, Tooltip, Avatar} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  entity: {
    borderRadius: "100%",
    backgroundColor: 'black',
    width: '60px',
    height: '60px',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: theme.spacing(2),
    },
    transition: theme.transitions.create('border-radius', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short
    }),
  },
  isSelected: {
    borderRadius: theme.spacing(2),
  },
  isSelectedBackground: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const EntityOption = ({ entityName, entityId, isSelected, onClick }) => {
  const classes = useStyles();

  return (
    <Tooltip title={entityName} placement="right">
      <Avatar onClick={onClick} className={clsx(
        classes.entity,
        {
          [classes.isSelected]: isSelected,
        }
      )}>

      </Avatar>
    </Tooltip>
  )
}

export default EntityOption;
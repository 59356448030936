import React, { useState, useEffect, } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useDebounce from 'src/hooks/useDebounce';
import StakeholdHttp from '../utils/api';
import Ledger from './Ledger';
import ErrorBoundary from './ErrorBoundary';
import EmptyState from "./EmptyState";

const DEFAULT_RESULTS = {
  count: 0,
  endIndex: 0,
  next: null,
  numPages: 1,
  page: 1,
  previous: null,
  results: [],
  startIndex: 0,
};

function HttpLedger({
  url,
  columns,
  actions,
  emptyState,
  render,
  defaultPageSize,
  defaultOrdering,
  defaultFilters,
  defaultSearch,
  isPaginated,
  isSearchable,
  ...rest
}: any) {
  const isMountedRef = useIsMountedRef();
  const [results, setResults] = useState(DEFAULT_RESULTS);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParams, setQueryParams] = useState({
    page_size: defaultPageSize,
    page: 1,
    ordering: defaultOrdering,
    filters: defaultFilters,
    search: defaultSearch,
  });
  const debouncedQueryParams = useDebounce(queryParams, 250);
  const fetchData = (params: any) => {
    setIsLoading(true);
    const payload = {
      ...params,
      ...params.filters,
    };
    delete payload.filters;
    new StakeholdHttp(url, payload).get()
      .then((result) => {
        if (isMountedRef.current) {
          setResults(result);
        }
      })
      .catch(() => { })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    if (debouncedQueryParams) {
      fetchData(debouncedQueryParams);
    }
  }, [debouncedQueryParams]);
  const updateQueryParams = (update: any) => {
    setIsLoading(true);
    const newQueryParams = {
      ...queryParams,
      ...update,
    };
    setQueryParams(newQueryParams);
  };
  const setPage = (page: any) => updateQueryParams({ page });
  const setLimit = (pageSize: any) => updateQueryParams({ page_size: pageSize });
  const setFilters = (filters: any) => updateQueryParams({ filters });
  const setSort = (ordering: any) => updateQueryParams({ ordering });
  const setQuery = (search: any) => updateQueryParams({ search, page: 1 });

  const handlePageChange = (event: any, newPage: any) => {
    // newPage is zero index'd but our api is not
    setPage(newPage + 1);
  };

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
  };

  const ledgerProps = {
    results: results.results ? results.results : results,
    rawResults: results,
    columns,
    actions,
    isLoading,
    emptyState: emptyState || <EmptyState />,
    setPage,
    handlePageChange,
    handleLimitChange,
    setLimit,
    setQuery,
    setSort,
    setFilters,
    query: queryParams.query,
    limit: queryParams.page_size,
    page: queryParams.page,
    isSearchable,
    isPaginated,
    fetchData: () => fetchData(queryParams),
    ...rest
  };
  const component = typeof render === 'function' ? render(ledgerProps) : <Ledger {...ledgerProps} />;
  return (
    <ErrorBoundary>
      {component}
    </ErrorBoundary>
  );
}
HttpLedger.defaultProps = {
  defaultPageSize: 10,
  defaultSort: null,
  defaultFilters: null,
  defaultSearch: null,
};
export default HttpLedger;

import React, { useState } from 'react';
import {
  Form, Formik, connect, FormikValues, FormikHelpers, useFormikContext
} from 'formik';
import {
  Grid, Box
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import debounce from 'just-debounce-it';

import Button, { Props as ButtonProps } from '../Button';

const AutoSave = ({ debounceMs }: { debounceMs: number }) => {
  const formik = useFormikContext();
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm]
  );

  React.useEffect(() => {
    if (formik.values !== formik.initialValues) debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <></>
  );
};

const DefaultButtons = ({
  primaryButton,
  secondaryButton,
  tertiaryButton,
  submissionInFlight,
  formActions
}: any) => {
  let submitButtons = null;
  if (!secondaryButton && !tertiaryButton) {
    const buttonProps = primaryButton && primaryButton.props || {};

    submitButtons = (
      <Grid item xs={12}>
        <Button
          isLoading={submissionInFlight}
          fullWidth
          look="primary"
          type="submit"
          {...buttonProps}
        >
          {primaryButton.label}
        </Button>
      </Grid>
    );
  } else if (secondaryButton || tertiaryButton) {
    submitButtons = (
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          {
            tertiaryButton && (
              <Button
                {...tertiaryButton.props}
                isLoading={submissionInFlight}
                onClick={() => tertiaryButton.onClick(formActions)}
              >
                {tertiaryButton.label}
              </Button>
            )
          }
          <Box display="flex">
            {
              secondaryButton && (
                <Button
                  {...secondaryButton.props}
                  onClick={() => secondaryButton.onClick(formActions)}
                >
                  {secondaryButton.label}
                </Button>
              )
            }
            <Box ml={1}>
              <Button
                look="primary"
                {...primaryButton.props}
                isLoading={submissionInFlight}
                type="submit"
              >
                {primaryButton.label}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  return submitButtons;
};

const ConnectedDefaultButtons = connect(DefaultButtons);

interface Props {
    onSubmit: (payload: FormikValues, afterSubmitCallback: () => void) => void,
    className?: string,
    children?: React.ReactNode,
    spacing: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
    validationSchema?: any,
    initialValues: FormikValues,
    autoSave?: boolean,
    primaryButton?: ButtonProps,
    secondaryButton?: ButtonProps,
    tertiaryButton?: ButtonProps,
    isLoading?: boolean,
}

const FormikForm = ({
  onSubmit,
  autoSave,
  className,
  children,
  spacing,
  validationSchema,
  initialValues,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  isLoading,
}: Props) => {
  /*
  Semi-heavyweight formik wrapper. Implements default form behaviors for use across Stakehold:
    - dont validate on change/blur
    - expose api for setting errors/warnings/info messages on the form
    - Wraps fields in a Grid container - expects all fields to be wrapped in Grid items
    - default buttons, alternatively pass your own in
    - default ui wrappers (i.e. card layout)
    - handles loading state
   */

  const [submissionInFlight, setSubmissionInFlight] = useState(false);
  const [formError, setFormError] = useState('');
  const [formWarning, setFormWarning] = useState('');
  const [formInfo, setFormInfo] = useState('');

  // Don't render the form until initialValues are ready.
  // InitialValues set after the first render will not be visible.
  if (isLoading) return null;

  const formActions = {
    setSubmissionInFlight,
    setFormError,
    setFormWarning,
    setFormInfo,
  };

  // todo - this is the ugliest part of the form
  const buttons = (
    <ConnectedDefaultButtons
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      tertiaryButton={tertiaryButton}
      submissionInFlight={submissionInFlight}
      formActions={formActions}
    />
  );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSubmissionInFlight(true);
        onSubmit(values, formActions);
      }}
    >
      <Form style={{ width: '100%' }}>
        <Grid container spacing={spacing}>
          {
            formError && (
              <Grid item xs={12}>
                <Alert severity="error">
                  {formError}
                </Alert>
              </Grid>
            )
          }
          {
            formWarning && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  {formWarning}
                </Alert>
              </Grid>
            )
          }
          {
            formInfo && (
              <Grid item xs={12}>
                <Alert severity="info">
                  {formInfo}
                </Alert>
              </Grid>
            )
          }
          {children}
          {autoSave ? <AutoSave debounceMs={300} /> : buttons}
        </Grid>
      </Form>
    </Formik>
  );
};

FormikForm.defaultProps = {
  onSubmit: () => {},
  primarySubmit: false,
  secondarySubmit: false,
  childrenBoxProps: {},
  primaryButton: {
    label: 'Submit'
  },
  spacing: 2,
  xs: 12,
  md: 12,
};

export default FormikForm;

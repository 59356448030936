import React from 'react';
import {Box, Checkbox, Divider, FormControlLabel} from "@material-ui/core";
import Content from "../../components/Content";
import useGetRequest from "../../hooks/useGetRequest";
import {useNavigate, useParams} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import Card from "../../components/Card";
import {bigCurrency, percent} from "../../utils/numbers";
import {SAFE} from "../constants";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import StakeholdHttp from "../../utils/api";
import {useSnackbar} from "notistack";
import useAuth from "../../hooks/useAuth";
import StakeholdCard from "../admin-experience/StakeholdCard";


const ViewInviteLink = () => {
  const { user } = useAuth();
  const { inviteLinkId } = useParams();
  const navigate = useNavigate();
  const { result, isLoading: offerLoading } = useGetRequest({
    url: `/apps/offers/for-invite-link/${inviteLinkId}/`,
    initialResult: {},
  });
  const { offer, offerInviteLink } = result;
  const [investmentAmount, setInvestmentAmount] = React.useState('');
  const [hasSelectedInvestmentAmount, setHasSelectedInvestmentAmount] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!user.accreditation.accreditedAt) {
    navigate(`/get-accredited?nextPath=${window.location.pathname}`)
  }

  const onSubmitInvestment = () => {
    new StakeholdHttp(
      // todo - needs to take invite link id
      `/apps/offers/${result.offer.id}/create-or-update-participant/`,
      {amount: investmentAmount}
      ).post().then(() => {
        enqueueSnackbar('Commitment submitted', { variant: 'success' });
      navigate(`/offers/${offer.id}`)
    }).catch(() => enqueueSnackbar('Failed to submit commitment', { variant: 'error' }))
  }

  if (offerLoading || !result) return null;

  const confirmation = (
    <StakeholdCard
      title={`You are investing ${bigCurrency(investmentAmount)}`}
      content={
        <>
        <FormControlLabel
          control={<Checkbox />}
          label="I agree to the Subscription Agreement with my electronic signature."
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="I understand startups are risks and can afford to lose my entire investment."
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="I understand these investments are not easily resold. I can wait years for a return."
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="I understand that the founder may reduce or reject my allocation for any reason."
          sx={{ mb: 2 }}
        />
        <Box mt={3}>
          <Button look="primary" fullWidth onClick={onSubmitInvestment}>
            Agree and submit
          </Button>
        </Box>
        </>
      }
    />
  );

  const enterAmount = (
    <StakeholdCard
      title={`Enter your investment amount in ${offer.issuerName}`}
      content={
        <>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item>
            <Box display="flex" flexDirection="column">
              <Typography variant="overline">
                Asset class
              </Typography>
              <Typography variant="body1">
                {offer.assetClass.name}
              </Typography>
            </Box>
          </Grid>
          {
            offer.assetClass.assetType === SAFE ? (
              <>
                <Grid item>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="overline">
                      Cap
                    </Typography>
                    <Typography variant="body1">
                      {offer.assetClass.valuationCap ? bigCurrency(offer.assetClass.valuationCap) : 'Uncapped'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="overline">
                      Discount
                    </Typography>
                    <Typography variant="body1">
                      {percent(offer.assetClass.valuationDiscount)}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Box display="flex" flexDirection="column">
                  <Typography variant="overline">
                    Price per share
                  </Typography>
                  <Typography variant="body1">
                    {offer.assetClass.valuationCap ? bigCurrency(offer.assetClass.valuationCap) : 'Uncapped'}
                  </Typography>
                </Box>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Box mt={3}>
              <TextField
                look="currency"
                label="Amount"
                value={investmentAmount}
                onChange={e => setInvestmentAmount(e.target.rawValue)}
                helperText={`Minimum: ${bigCurrency(offerInviteLink.minInvestment)} | Maximum: ${bigCurrency(offerInviteLink.maxInvestment)}`}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth look="primary" onClick={() => setHasSelectedInvestmentAmount(true)}>
              Continue
            </Button>
          </Grid>
        </Grid>
        </>
      }
    />
  )

  return (
    <Content title={`${offer.issuerName}'s ${offer.name}`} maxWidth="lg" breadcrumbs={[
      {
        title: 'Invites',
        route: '/invites',
      },
    ]}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} lg={5}>
          {hasSelectedInvestmentAmount ? confirmation : enterAmount}
        </Grid>
      </Grid>
    </Content>
  )
};

export default ViewInviteLink;
import React from 'react';

import Content from 'src/components/Content';
import HttpLedger from "../../components/HttpLedger";
import EmptyState from "../../components/EmptyState";
import {useParams} from "react-router-dom";
import Label from "../../components/Label";
import {bigCurrency} from "../../utils/numbers";
import Button from "../../components/Button";
import {Box} from "@material-ui/core/index";
import useModal from "../../components/modal/useModal";
import CommitmentAcceptanceConfirmationModal from "./CommitmentAcceptanceConfirmationModal";
import Ledger from "../../components/Ledger";
import CommitmentRejectionConfirmationModal from "./CommitmentRejectionConfirmationModal";
import OfferParticipantStatus from "./OfferParticipantStatus";


function Stakeholders() {
  const { entityId: issuerId } = useParams();
  const {
    isOpen: acceptStakeholdersModalIsOpen,
    openModal: openAcceptStakeholdersModal,
    closeModal: closeAcceptStakeholdersModal,
    modalContent: acceptedOfferParticipants,
  } = useModal();
  const {
    isOpen: rejectStakeholdersModalIsOpen,
    openModal: openRejectStakeholdersModal,
    closeModal: closeRejectStakeholdersModal,
    modalContent: rejectedOfferParticipants,
  } = useModal();

  const columns = [
    {
      title: 'Stakeholder name',
      cellContent: (participant: any) => participant.ownerName,
    },
    {
      title: 'Offer',
      cellContent: (participant: any) => participant.offerName,
    },
    {
      title: 'Status',
      cellContent: (participant: any) => <OfferParticipantStatus status={participant.status} />
    },
    {
      title: 'Commitment',
      cellContent: (participant: any) => bigCurrency(participant.amount),
    },
    {
      title: 'Accepted',
      cellContent: (participant: any) => bigCurrency(participant.owed),
    },
  ];

  const emptyState = (
    <EmptyState text="Stakeholders invited to your offers will appear here" />
  );

  const bulkActions = ({ selectedRows }) => {
    return (
      <Box display="flex" mb={1}>
        <Button look="primary" onClick={() => openAcceptStakeholdersModal(selectedRows)}>
          Bulk accept
        </Button>
        <Box ml={1}>
          <Button  onClick={() => openRejectStakeholdersModal(selectedRows)}>
            Bulk reject
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Content title="Stakeholders" maxWidth="lg">
      <HttpLedger
        url={`/apps/offer-participants/for-issuer/${issuerId}/`}
        columns={columns}
        emptyState={emptyState}
        BulkActions={bulkActions}
        enableBulkOperations
        enableSelectAll
        render={({
            fetchData,
            ...ledgerProps
          }: any) => (
            <>
              {
                acceptStakeholdersModalIsOpen && (
                  <CommitmentAcceptanceConfirmationModal
                    fetchData={fetchData}
                    onClose={closeAcceptStakeholdersModal}
                    offerParticipants={acceptedOfferParticipants}
                  />
                )
              }
              {
                rejectStakeholdersModalIsOpen && (
                  <CommitmentRejectionConfirmationModal
                    fetchData={fetchData}
                    onClose={closeRejectStakeholdersModal}
                    offerParticipants={rejectedOfferParticipants}
                  />
                )
              }
              <Ledger {...ledgerProps} />
            </>
          )}
      />

    </Content>
  );
}

export default Stakeholders;

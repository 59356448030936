import axios from 'axios';
import toCamelCase from './toCamelCase';
import toSnakeCase from './toSnakeCase';
import authService from '../services/authService';

class StakeholdHttp {
  api_domain: any;

  headers: any;

  payload: any;

  url: any;

  withAuth: any;

  withCasing: any;

  /*
  Little axios wrapper to talk to our backend.
  Makes it easy to send requests with/without authorization credentials
  and converts responses/payloads to/from camelCase and snakeCase
   */
  constructor(url: string, payload: any = null) {
    this.api_domain = process.env.REACT_APP_API_URL;
    this.url = `${this.api_domain}${url}`;
    this.payload = payload || {};
    this.withAuth = true;
    this.withCasing = true;
    this.headers = {};
  }

  withoutAuth() {
    this.withAuth = false;
    return this;
  }

  withoutCasing() {
    this.withCasing = false;
    return this;
  }

  _processResponse = (res: any) => (this.withCasing ? toCamelCase(res.data) : res.data);

  _processPayload(payload: any) {
    return this.withCasing ? toSnakeCase(payload) : payload;
  }

  setHeader(key: any, value: any) {
    this.headers[key] = value;
  }

  _setAuthorizationHeader() {
    if (this.withAuth) this.setHeader('Authorization', `Bearer ${authService.getAccessToken()}`);
  }

  _getHeaders() {
    this._setAuthorizationHeader();
    return { headers: this.headers };
  }

  get() {
    return axios.get(
      this.url,
      {
        params: this.payload,
        ...this._getHeaders(),
      },
    ).then(this._processResponse);
  }

  post() {
    return axios.post(
      this.url,
      this._processPayload(this.payload),
      this._getHeaders()
    ).then(this._processResponse);
  }

  patch() {
    return axios.patch(
      this.url,
      this._processPayload(this.payload),
      this._getHeaders()
    ).then(this._processResponse);
  }

  delete() {
    return axios.delete(
      this.url,
      {
        data: this._processPayload(this.payload),
        ...this._getHeaders()
      }
    ).then(this._processResponse);
  }
}

export default StakeholdHttp;
// COMMON
export const uploadFile = (file: any) => new StakeholdHttp('/common/create-presign-post/', file).post();
export const createPresignedPost = (payload: any) => new StakeholdHttp('/common/create-presigned-post/', payload).withoutCasing().post();

// INTEGRATION
export const authorizeGmailAccount = (payload: any) => new StakeholdHttp('/automatic/integration/gmail/authorize/', payload).withoutCasing().post();
export const syncGmailAccount = (payload: any) => new StakeholdHttp('/automatic/integration/gmail/sync/', payload).withoutCasing().post();
export const listGoogleSyncedEmail = (payload: any) => new StakeholdHttp('/automatic/integration/gmail/list/', payload).withoutCasing().get();
export const markMessageRead = (payload: any) => new StakeholdHttp('/automatic/integration/gmail/mark-read/', payload).withoutCasing().post();

export const plaidLinkToken = (payload: any) => new StakeholdHttp('/automatic/integration/plaid/link-token/', payload).withoutCasing().get();
export const authorizePlaidAccount = (payload: any) => new StakeholdHttp('/automatic/integration/plaid/authorize/', payload).withoutCasing().get();

// IDENTITY
export const signUp = (payload: any) => new StakeholdHttp('/identity/users/', payload).withoutAuth().post();
export const getCurrentUser = () => new StakeholdHttp('/identity/me/').get();
export const logInWithUsernamePassword = (payload: any) => new StakeholdHttp('/identity/login/', payload).withoutAuth().post();
export const sendEmailVerificationEmail = (payload: any) => new StakeholdHttp('/identity/send-email-verification-email/', payload).withoutAuth().post();
export const sendResetPasswordEmail = (payload: any) => new StakeholdHttp('/identity/send-reset-password-email/', payload).withoutAuth().post();
export const confirmResetPassword = (payload: any) => new StakeholdHttp('/identity/confirm-reset-password/', payload).withoutAuth().post();

// CLIENTS
export const inviteClientToCompany = (payload: any) => new StakeholdHttp('/automatic/clients/', payload).post();
export const deleteClient = (clientId: any) => new StakeholdHttp(`/automatic/clients/${clientId}/`).delete();

// APPLICATION PORTAL
export const sendApplicationEmail = (engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/emails/send-application-email/`).post();
export const createClientProfile = (engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/client-profiles/`).post();
export const deleteClientProfile = (clientProfileId: any) => new StakeholdHttp(`/automatic/client-profiles/${clientProfileId}/`).delete();
export const updateAsset = (payload: any, engagementId: any, assetId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/assets/${assetId}/`, payload).patch();
export const createAsset = (payload: any, engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/assets/`, payload).post();
export const generate1003pdf = (engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/outputs/form-1003-pdf/`).get();
export const generate1003fnma = (engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/outputs/form-1003-fnma/`).get();
export const getPresignedUrlForDocument = (engagementId: any, documentId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/presigned-document-url/${documentId}/`).get();
export const uploadEngagementDocument = (engagementId: any, document: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/documents/`, document).withoutCasing().post();
export const uploadTaskDocuments = (engagementId: any, taskId: any, documents: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/tasks/${taskId}/upload-task-documents/`, documents).withoutCasing().post();
export const completeApplication = (engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/application/`).patch();

// CLIENT EXPERIENCE
export const listEngagementsForClient = () => new StakeholdHttp('/automatic/client-engagements/').get();

// BROKER EXPERIENCE
export const updateEngagementStep = (engagementId: any, payload: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/update-step/`, payload).patch();
export const previewEmailForTemplate = (engagementId: any, templateId: any) => new StakeholdHttp(`/automatic/email-templates/${templateId}/preview/engagements/${engagementId}/`).get();
export const getContextForFields = (engagementId: any, fields: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/get-context-for-fields/`, fields).withoutCasing().post();
export const getRealtor = (realtorId: any) => new StakeholdHttp(`/automatic/realtors/${realtorId}/`).get();
export const readNotifications = () => new StakeholdHttp('/automatic/notifications/read/').post();
export const createEmailTemplate = (workflowId: any, template: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/email-templates/`, template).post();
export const updateEmailTemplate = (workflowId: any, id: any, template: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/email-templates/${id}/`, template).patch();
export const archiveEngagement = (engagementId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/archive/`).post();
export const sendEmailForEngagement = (engagementId: any, payload: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/send-email/`, payload).post();
export const sendNotification = (engagementId: any, payload: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/send-notification/`, payload).post();
export const sendGenericEmail = (payload: any) => new StakeholdHttp('/automatic/send-email/', payload).post();
export const createScheduledEmail = (engagementId: any, payload: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/scheduled-emails/`, payload).post();
export const updateScheduledEmail = (engagementId: any, scheduledEmailId: any, payload: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/scheduled-emails/${scheduledEmailId}/`, payload).patch();
export const getScheduledEmail = (engagementId: any, scheduledEmailId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/scheduled-emails/${scheduledEmailId}/`).get();
export const getCalendarEventsForEngagement = (engagementId: any, queryParams: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/calendar-events/`, queryParams).get();
export const getCalendarEventsForTeamMember = (queryParams: any) => new StakeholdHttp('/automatic/events/', queryParams).get();
export const getWorkflow = () => new StakeholdHttp('/automatic/workflows/').get();
export const savePerspective = (workflowId: any, perspectiveId: any, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/perspectives/${perspectiveId}/`, payload).patch();
export const saveWorkflowProcess = (workflowId: any, payload: any) => new StakeholdHttp(`/automatic/update-workflow-process/${workflowId}/`, payload).patch();
export const completeTask = (engagementId: any, taskId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/tasks/${taskId}/complete/`).post();
export const dismissTask = (engagementId: any, taskId: any) => new StakeholdHttp(`/automatic/engagements/${engagementId}/tasks/${taskId}/dismiss/`).post();
export const saveWidgets = (workflowId: any, perspectiveId: any, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/perspectives/${perspectiveId}/widgets/`, payload).patch();
export const saveWidget = (workflowId: any, perspectiveId: any, widgetId: any, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/perspectives/${perspectiveId}/widgets/${widgetId}/`, payload).patch();
export const createWidget = (workflowId: any, perspectiveId: any, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/perspectives/${perspectiveId}/widgets/`, payload).post();
export const deleteWidget = (workflowId: any, perspectiveId: any, widgetId: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/perspectives/${perspectiveId}/widgets/${widgetId}/`).delete();

export const createTaskTemplate = (workflowId: any, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/task-templates/`, payload).post();

export const createStepWithAction = (workflowId: string, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/create-step-with-action/`, payload).post();
export const createStepWithTrigger = (workflowId: string, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/create-step-with-trigger/`, payload).post();
export const deleteStep = (workflowId: string, stepId: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/steps/${stepId}/`).delete();
export const createEdge = (workflowId: string, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/edges/`, payload).post();
export const submitForm = (workflowId: string, formId: string, payload: any) => new StakeholdHttp(`/automatic/workflows/${workflowId}/forms/${formId}/submit/`, payload).post();

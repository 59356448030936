import React from 'react';
import { useField, connect, FormikContextType } from 'formik';

import TextField from '../TextField';

interface Props {
  name: string,
  formik: FormikContextType<any>,
  label: string,
  fullWidth?: boolean,
  multiline?: boolean,
  rows?: number
}

const FormikInput = (props: any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField({ name: props.name });
  const error = meta.touched && meta.error;
  return (
    <TextField
      {...field}
      {...props}
      value={field.value || ''}
      onChange={(e: any) => props.formik.setFieldValue(props.name, e.target.rawValue || e.target.value)}
      onBlur={() => props.formik.setFieldTouched(props.name)}
      error={Boolean(error)}
      helperText={error || props.helperText}
    />
  );
};

export default connect(FormikInput);

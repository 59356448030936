import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Button, { Props as ButtonProps } from './Button';

const useStyles = makeStyles((theme) => ({
  action: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

interface Props extends ButtonProps {
  icon: React.ReactNode,
}

function ActionButton({ icon, children, ...props }: Props) {
  const classes = useStyles();

  return (
    <Button {...props}>
      <SvgIcon
        fontSize="small"
        className={classes.actionIcon}
      >
        {icon}
      </SvgIcon>
      {children}
    </Button>
  );
}

export default ActionButton;

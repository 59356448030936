import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import { Box } from '@material-ui/core/index';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Scrollbar from '../../components/Scrollbar';
import useGetRequest from "../../hooks/useGetRequest";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: APP_BAR_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingBottom: APP_BAR_DESKTOP + 24,
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const { result: entities, isLoading } = useGetRequest({
      url: `/apps/entities/list-for-current-user/`,
      initialResult: [],
  });

  if (isLoading) return null;

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        entities={entities}
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <Box sx={{ height: '100vh', width: '100%' }}>
        <Scrollbar
          sx={{
            height: '100%',
            '& .simplebar-content': {
              height: '100%', display: 'flex', flexDirection: 'column',
            }
          }}
        >
          <MainStyle>
            <Outlet />
          </MainStyle>
        </Scrollbar>
      </Box>
    </RootStyle>
  );
}

import React from 'react';
import Label from "./Label";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: "pointer",
  },
}));

const Code = ({ text, ...props }) => {
  const classes = useStyles();
  return (
    <Label
      {...props}
      className={clsx(
        props.className,
        { [classes.clickable]: Boolean(props.onClick) }
      )}
    >
      <code>{text}</code>
    </Label>
  );
}

export default Code;
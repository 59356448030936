import React from 'react';
import { Box, Container } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';

function Content({
  children, title, breadcrumbs, actions, maxWidth = false
}: any) {
  return (
    <Page>
      <Container maxWidth={maxWidth}>
        <Header
          breadcrumbs={breadcrumbs}
          title={title}
          actions={actions}
        />
        <Box mt={3}>
          {children}
        </Box>
      </Container>
    </Page>
  );
}
export default Content;

import React from 'react';

import BaseModal from 'src/components/modal/BaseModal';
import Button from "../../components/Button";
import StakeholdHttp from "../../utils/api";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {bigCurrency} from "../../utils/numbers";
import {Box} from "@material-ui/core/index";
import TextField from "../../components/TextField";
import {Alert} from "@material-ui/lab";

interface Props {
  fetchData: () => void,
  onClose: () => void,
  offerParticipants: any[],
}

function CommitmentAcceptanceConfirmationModal({
  onClose,
  fetchData,
  offerParticipants,
}: Props) {
  const onAcceptCommitments = () => {
    const rejectedOfferParticipantIds = offerParticipants.map(({id}) => id);
    new StakeholdHttp(`/apps/commitments/reject-for-participants/`, {rejectedOfferParticipantIds}).post().then(res => {
      fetchData();
      onClose()
    })
  };


  return (
    <BaseModal
      onClose={onClose}
      title="Reject stakeholders"
      isOpen
    >
      <Alert severity="warning">
          Stakeholders will be notified of their rejection via email
        </Alert>
      <Box mt={2} mb={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Stakeholder</TableCell>
                <TableCell>Offer</TableCell>
                <TableCell align="right">Amount committed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offerParticipants.map(({ id, ownerName, offerName, amount }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {ownerName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {offerName}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {bigCurrency(amount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Button look="primary" onClick={onAcceptCommitments} fullWidth>
        Reject commitments
      </Button>
    </BaseModal>
  );
}

export default CommitmentAcceptanceConfirmationModal;

import React from 'react';
import Label from "../../components/Label";

export const VIEWED = 'VIEWED'
export const ACTIVE = 'ACTIVE'
export const FUNDS_SENT = 'FUNDS_SENT'
export const FUNDS_RECEIVED = 'FUNDS_RECEIVED'
export const READY_TO_ACCEPT = 'READY_TO_ACCEPT'
export const REJECTED = 'REJECTED'
export const ACCEPTED = 'ACCEPTED'
export const PENDING_ISSUANCE = 'PENDING_ISSUANCE'
export const COMPLETED = 'COMPLETED'
export const CANCELED = 'CANCELED'

const LABEL_BY_TYPE = {
  [VIEWED]: 'Viewed',
  [ACTIVE]: 'Active',
  [FUNDS_SENT]: 'Ready to accept',
  [FUNDS_RECEIVED]: 'Funds received',
  [READY_TO_ACCEPT]: 'Ready to accept',
  [REJECTED]: 'Rejected',
  [ACCEPTED]: 'Accepted',
  [PENDING_ISSUANCE]: 'Pending issuance',
  [COMPLETED]: 'Completed',
  [CANCELED]: 'Canceled',
}

const OfferParticipantStatus = ({ status }) => (
  <Label color="success">{LABEL_BY_TYPE[status].toUpperCase()}</Label>
);

export default OfferParticipantStatus;
import React from "react";
import {Box, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import Button from "../../../components/Button";
import StakeholdHttp from "../../../utils/api";


const Attestation = ({handleAccreditationChange}) => {
  const [investmentExperienceLevel, handleInvestmentExperienceLevelChange] = React.useState();
  const updateInvestmentExperienceLevel = () => {
    new StakeholdHttp('/apps/accreditations/update-investment-experience-level/', { investmentExperienceLevel }).patch().then(res => {
      handleAccreditationChange(res)
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mb={3}>
      <Typography variant="h5">
        What is your familiarity with investing?
      </Typography>
        </Box>
        <RadioGroup
          value={investmentExperienceLevel}
          onChange={e => handleInvestmentExperienceLevelChange(e.target.value)}
        >
          <FormControlLabel sx={{ marginBottom: 2 }} value="NOVICE" control={<Radio />} label="I'm new to investing" />
          <FormControlLabel sx={{ marginBottom: 2 }} value="BEGINNER" control={<Radio />} label="I have an employer-based 401k, IRA, or other retirement account but my involvement is limited in selecting the actual investments" />
          <FormControlLabel sx={{ marginBottom: 2 }} value="COMPETENT" control={<Radio />} label="I'm relatively experienced when it comes to investing in the stock market and may be familiar with real estate investing" />
          <FormControlLabel sx={{ marginBottom: 4 }} value="PROFICIENT" control={<Radio />} label="I invest in a wide variety of stock and bonds, this can include real estate investing" />
          <FormControlLabel sx={{ marginBottom: 4 }} value="EXPERT" control={<Radio />} label="I have experience investing in private real estate deals or have owned my own investment properties" />
        </RadioGroup>
      <Button look="primary" onClick={updateInvestmentExperienceLevel}>
        Continue
      </Button>
    </Box>
  )
};

export default Attestation;

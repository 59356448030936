// material
import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Workmark({ sx }: BoxProps) {
  return (
    <Box sx={{ ...sx }}>

<svg width="263" height="38" viewBox="0 0 527 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_355_679)">
<path d="M117.662 26.4668C116.785 25.3897 115.667 24.5346 114.398 23.9708C113.085 23.3165 111.64 22.9718 110.174 22.9628C109.317 22.9611 108.464 23.0577 107.63 23.2508C106.808 23.4481 106.028 23.7893 105.326 24.2588C104.626 24.6958 104.035 25.2868 103.598 25.9867C103.158 26.7605 102.942 27.6413 102.974 28.5307C102.948 29.3525 103.147 30.1658 103.55 30.8827C103.933 31.5632 104.477 32.1399 105.134 32.5627C105.884 33.085 106.709 33.4897 107.582 33.7627L110.75 34.8187L114.782 36.2586C116.164 36.7897 117.471 37.4997 118.67 38.3706C119.838 39.294 120.816 40.435 121.549 41.7306C122.292 43.2205 122.671 44.8657 122.654 46.5305C122.709 48.5179 122.298 50.4907 121.454 52.2905C120.697 53.8585 119.598 55.2364 118.238 56.3224C116.812 57.4433 115.182 58.2748 113.438 58.7704C111.581 59.3006 109.657 59.5593 107.726 59.5384C105.068 59.5356 102.432 59.0476 99.9497 58.0984C97.5524 57.1842 95.4232 55.6822 93.7578 53.7304L98.9418 48.9305C99.9696 50.3524 101.34 51.4916 102.926 52.2425C104.416 52.9958 106.056 53.4058 107.726 53.4424C108.601 53.4429 109.472 53.33 110.318 53.1065C111.182 52.9257 112 52.566 112.718 52.0505C113.412 51.547 113.999 50.9107 114.446 50.1785C114.898 49.3228 115.114 48.3616 115.07 47.3945C115.1 46.4471 114.85 45.5118 114.35 44.7066C113.796 43.9792 113.113 43.3607 112.334 42.8826C111.379 42.3221 110.365 41.8712 109.31 41.5386L105.71 40.2906C104.455 39.9065 103.222 39.458 102.014 38.9467C100.797 38.4155 99.6782 37.6855 98.7018 36.7866C97.6968 35.8654 96.8815 34.7566 96.3018 33.5227C95.6367 32.0129 95.3249 30.3713 95.3898 28.7227C95.3382 26.8629 95.7857 25.0232 96.6858 23.3948C97.5327 21.9114 98.6963 20.6331 100.094 19.6508C101.558 18.66 103.181 17.9293 104.894 17.4909C106.678 17.0131 108.518 16.771 110.366 16.7708C112.506 16.7699 114.631 17.1268 116.654 17.8268C118.722 18.5264 120.62 19.655 122.222 21.1388L117.662 26.4668Z" fill="black"/>
<path d="M150.542 24.2595H138.014V17.9236H170.269V24.2595H157.789V58.7231H150.542V24.2595Z" fill="black"/>
<path d="M195.707 17.9236H201.947L219.515 58.7231H211.259L207.419 49.3633H189.755L186.059 58.7231H177.947L195.707 17.9236ZM204.923 43.1713L198.635 26.5635L192.251 43.1713H204.923Z" fill="black"/>
<path d="M235.596 17.9236H243.084V35.5394L260.123 17.9236H269.723L250.524 36.7873L270.875 58.7231H260.651L243.084 38.2753V58.7231H235.788L235.596 17.9236Z" fill="black"/>
<path d="M287.291 17.9236H314.315V24.2595H294.539V34.6274H313.259V40.7233H294.539V52.2432H315.323V58.7231H287.291V17.9236Z" fill="black"/>
<path d="M334.283 17.9236H341.531V34.3874H360.731V17.9236H367.883V58.7231H360.635V40.6273H341.435V58.7231H334.283V17.9236Z" fill="black"/>
<path d="M386.459 38.2273C386.423 35.1851 386.994 32.1664 388.139 29.3474C389.154 26.8294 390.692 24.5554 392.651 22.6754C394.646 20.8034 396.996 19.3507 399.563 18.4035C402.361 17.3853 405.321 16.8812 408.298 16.9155C411.308 16.8781 414.299 17.3821 417.13 18.4035C419.717 19.3377 422.086 20.7915 424.09 22.6754C426.081 24.5378 427.638 26.8157 428.65 29.3474C429.795 32.1664 430.366 35.1851 430.33 38.2273C430.362 41.2069 429.791 44.1622 428.65 46.9152C427.628 49.4835 426.074 51.8064 424.09 53.7311C422.093 55.6592 419.725 57.1616 417.13 58.1471C414.312 59.2256 411.316 59.7629 408.298 59.731C405.313 59.7509 402.351 59.2138 399.563 58.1471C396.988 57.1489 394.638 55.6476 392.651 53.7311C390.696 51.7919 389.16 49.472 388.139 46.9152C386.998 44.1622 386.427 41.2069 386.459 38.2273ZM394.379 38.2273C394.357 40.2711 394.698 42.3026 395.387 44.2272C396.014 46.0046 396.994 47.6371 398.266 49.0271C399.503 50.366 401.007 51.4292 402.682 52.1472C404.479 52.9197 406.415 53.3181 408.37 53.3181C410.326 53.3181 412.262 52.9197 414.058 52.1472C415.754 51.4417 417.277 50.3772 418.522 49.0271C419.795 47.6371 420.775 46.0046 421.402 44.2272C422.09 42.3026 422.432 40.2711 422.41 38.2273C422.427 36.215 422.086 34.2159 421.402 32.3234C420.766 30.5608 419.805 28.9328 418.57 27.5234C417.314 26.1734 415.795 25.0952 414.106 24.3554C412.289 23.5689 410.326 23.1762 408.346 23.2034C406.382 23.1772 404.435 23.57 402.634 24.3554C400.96 25.0961 399.457 26.1746 398.219 27.5234C396.971 28.9237 396.009 30.5542 395.387 32.3234C394.703 34.2159 394.361 36.215 394.379 38.2273Z" fill="black"/>
<path d="M449.002 17.9236H456.25V52.2432H473.482V58.7231H449.002V17.9236Z" fill="black"/>
<path d="M490.041 17.9236H504.441C507.148 17.9231 509.843 18.2782 512.457 18.9796C515.073 19.6291 517.536 20.787 519.705 22.3875C521.914 24.0478 523.706 26.2009 524.937 28.6754C526.365 31.6687 527.056 34.9603 526.953 38.2753C527.034 41.4649 526.342 44.6269 524.937 47.4913C523.693 49.9709 521.904 52.137 519.705 53.8272C517.537 55.4833 515.079 56.7204 512.457 57.4751C509.859 58.2688 507.157 58.6732 504.441 58.6751H490.041V17.9236ZM503.337 52.3872C505.244 52.3742 507.144 52.1488 509.001 51.7152C510.825 51.2932 512.552 50.5276 514.089 49.4592C515.633 48.339 516.898 46.877 517.785 45.1873C518.823 43.0529 519.318 40.695 519.225 38.3234C519.319 35.8589 518.825 33.4075 517.785 31.1714C516.912 29.4593 515.645 27.9787 514.089 26.8515C512.545 25.8266 510.817 25.1095 509.001 24.7395C507.136 24.3609 505.239 24.168 503.337 24.1635H497.481V52.3872H503.337Z" fill="black"/>
</g>
<path d="M30.2695 72.1387C30.2695 73.3985 30.77 74.6068 31.6608 75.4976C32.5517 76.3884 33.7599 76.8889 35.0197 76.8889H57.7756C67.0055 76.8889 74.4899 69.7716 74.4899 60.9926C74.4899 52.2136 67.0055 45.0964 57.7756 45.0964H35.0197C33.7605 45.0964 32.5527 45.5964 31.662 46.4865C30.7712 47.3767 30.2704 48.5841 30.2695 49.8434V72.1387Z" fill="#3167F1"/>
<path d="M44.2203 53.4598C44.2169 54.7174 43.715 55.9224 42.8245 56.8104C41.934 57.6985 40.7278 58.1972 39.4701 58.1972H16.7143C7.48116 58.1972 0 51.0799 0 42.3009C0 33.5219 7.48116 26.4046 16.7143 26.4046H44.2203V53.4598Z" fill="#4C7EFF"/>
<path d="M20.7891 18.6981H57.773C67.0029 18.6981 74.4873 25.8153 74.4873 34.5944V61.0023C74.4873 52.2232 67.0029 45.106 57.773 45.106H25.5393C24.2794 45.106 23.0712 44.6055 22.1804 43.7147C21.2895 42.8238 20.7891 41.6156 20.7891 40.3558V18.6981Z" fill="#7299FF"/>
<path d="M53.695 4.75987C53.6963 4.13525 53.5743 3.51651 53.3362 2.93908C53.098 2.36164 52.7483 1.83685 52.3071 1.39473C51.8659 0.952606 51.3418 0.601824 50.7648 0.3625C50.1879 0.123176 49.5694 -1.29194e-06 48.9448 0H16.7143C7.48116 0 0 7.11725 0 15.8963V42.3042C0 33.5251 7.48116 26.4079 16.7143 26.4079H48.9448C50.2046 26.4079 51.4128 25.9074 52.3037 25.0166C53.1945 24.1258 53.695 22.9175 53.695 21.6577V4.75987Z" fill="#AFC6FF"/>
<defs>
<clipPath id="clip0_355_679">
<rect width="433.195" height="42.8635" fill="white" transform="translate(93.8047 16.9156)"/>
</clipPath>
</defs>
</svg>

    </Box>
  );
}

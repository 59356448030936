import React from 'react'
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import {Box} from "@material-ui/core/index";

export default () => {
  // const { width, height } = useWindowSize()
  return (
    <Box position="fixed" sx={{top: 0, left: 0}}>
      <Confetti
        width={window.outerWidth}
        height={window.outerHeight}
      />
    </Box>
  )
}
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import React from "react";
import {Typography} from "@material-ui/core/index";
import {bigCurrency} from "../../utils/numbers";

const WireInstructionsTable = ({ wireInstruction }) => {
  if (!wireInstruction) return null;
  const rows = [
    {
      title: 'Bank name',
      value: wireInstruction.bankName,
    },
    {
      title: 'Bank address',
      value: wireInstruction.bankAddress,
    },
    {
      title: 'ABA / Routing number',
      value: wireInstruction.bankRoutingNumber,
    },
    {
      title: 'Account number',
      value: wireInstruction.beneficiaryAccountNumber,
    },
    {
      title: 'Beneficiary',
      value: wireInstruction.beneficiaryName,
    },
    {
      title: 'Beneficiary Address',
      value: wireInstruction.beneficiaryAddress,
    },
    {
      title: 'SWIFT',
      value: wireInstruction.swiftCode,
    },
    {
      title: 'IBAN',
      value: wireInstruction.iban,
    },
    {
      title: 'Amount',
      value: bigCurrency(wireInstruction.amount),
    },
    {
      title: 'Memo',
      value: wireInstruction.memo,
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.title}
              // sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell sx={{ backgroundColor: 'divider' }}>
                <Typography variant="subtitle2">
                  {row.title}
                </Typography>
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WireInstructionsTable;
import {
  useState,
  useEffect,
} from 'react';
import { useSnackbar } from 'notistack';
import { FormikValues } from 'formik';
import StakeholdHttp from '../../utils/api';

function CRUDForm({
  url: originalUrl,
  initialValuesOverride = {},
  beforeSubmit,
  onSuccess,
  onFetchSuccess,
  onFetchError,
  onError,
  afterSubmit,
  render,
  objectId,
  submitMethodOverride
}: any) {
  const url = objectId ? `${originalUrl}${objectId}/` : originalUrl;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<FormikValues>(initialValuesOverride);
  const { enqueueSnackbar } = useSnackbar();
  const getInitialValues = () => {
    // don't try to get initialValues if we are providing them or they dont yet exist for this objec
    if (Object.keys(initialValuesOverride).length || (!objectId && !submitMethodOverride)) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    new StakeholdHttp(url).get()
      .then((_result) => {
        setResult(_result);
        if (onFetchSuccess) onFetchSuccess(_result);
      })
      .catch((error) => {
        setResult(initialValuesOverride);
        if (onFetchError) onFetchError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const submit = (payload: any, formActions: () => void) => {
    if (beforeSubmit) beforeSubmit();

    let http;
    if (submitMethodOverride === 'patch' || objectId) {
      http = new StakeholdHttp(url, payload).patch();
    } else {
      http = new StakeholdHttp(url, payload).post();
    }

    http.then((res) => {
      if (formActions) formActions.setSubmissionInFlight(false);
      if (onSuccess) onSuccess(res);
    })
      .catch(() => {
        if (formActions) formActions.setSubmissionInFlight(false);
        if (onError) onError();
        enqueueSnackbar('There was an issue saving', {
          variant: 'error'
        });
      })
      .finally(() => {
        if (afterSubmit) afterSubmit();
      });
  };

  const destroy = () => {
    if (beforeSubmit) beforeSubmit();

    new StakeholdHttp(url).delete()
      .then((res) => {
        enqueueSnackbar('Deleted', {
          variant: 'success'
        });
        if (onSuccess) onSuccess(res);
      })
      .catch(() => {
        enqueueSnackbar('There was an issue deleting', {
          variant: 'error'
        });
        if (onError) onError();
      })
      .finally(() => {
        if (afterSubmit) afterSubmit();
      });
  };

  useEffect(() => {
    getInitialValues();
  }, [objectId]);

  return render(
    {
      onSubmit: submit,
      onDelete: destroy,
      isLoading,
      result,
      setResult,
    }
  );
}

export default CRUDForm;

import React from 'react';

import BaseModal from 'src/components/modal/BaseModal';
import Button from "../../components/Button";
import StakeholdHttp from "../../utils/api";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {bigCurrency} from "../../utils/numbers";
import {Box} from "@material-ui/core/index";
import TextField from "../../components/TextField";

interface Props {
  fetchData: () => void,
  onClose: () => void,
  offerParticipants: any[],
}

function CommitmentAcceptanceConfirmationModal({
  onClose,
  fetchData,
  offerParticipants,
}: Props) {
  const commitmentAmountByOfferParticipantId = Object.fromEntries(offerParticipants.map(({ id, amount }) => [id, amount]));
  const [acceptedAmountByOfferParticipantId, setAcceptedAmountByOfferParticipantId] = React.useState(commitmentAmountByOfferParticipantId);

  const onAcceptCommitments = () => {
    new StakeholdHttp(`/apps/commitments/accept-for-participants/`, {acceptedAmountByOfferParticipantId}).post().then(res => {
      fetchData();
      onClose()
    })
  };


  return (
    <BaseModal
      onClose={onClose}
      title="Confirm accept commitments"
      isOpen
    >
      <Box mb={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Stakeholder</TableCell>
                <TableCell>Offer</TableCell>
                <TableCell align="right">Amount committed</TableCell>
                <TableCell align="right">Amount to accept</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offerParticipants.map(({ id, ownerName, offerName, amount }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {ownerName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {offerName}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {bigCurrency(amount)}
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      look="currency"
                      value={acceptedAmountByOfferParticipantId[id]}
                      onChange={e => setAcceptedAmountByOfferParticipantId({
                        ...acceptedAmountByOfferParticipantId,
                        [id]: e.target.rawValue
                      })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Button look="primary" onClick={onAcceptCommitments} fullWidth>
        Accept commitments
      </Button>
    </BaseModal>
  );
}

export default CommitmentAcceptanceConfirmationModal;

import React, { Suspense, lazy } from 'react';
import {
  Navigate, useRoutes, useLocation
} from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
// components
import LoadingScreen from '../components/LoadingScreen';

import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import useSettings from '../hooks/useSettings';
import Offering from "../views/admin-experience/Offering";
import ViewInviteLink from "../views/shared/ViewInviteLink";
import Stakeholders from "../views/admin-experience/Stakeholders";
import ViewCommitmentsForCurrentUser from "../views/admin-experience/ViewCommitmentsForCurrentUser";
import GetAccredited from "../views/admin-experience/accreditation/GetAccredited";
import ViewOfferForParticipant from "../views/admin-experience/ViewOfferForParticipant";

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={(
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      )}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { isAuthenticated } = useAuth();
  const { view } = useSettings();

  const COMMON_ROUTES = [
  // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ];

  const USER_ROUTES = [
    // Dashboard Routes
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/commitments" replace /> },
        { path: '/invites/:inviteLinkId', element: <ViewInviteLink /> },
        { path: '/offers/:offerId', element: <ViewOfferForParticipant />},
        { path: '/commitments', element: <ViewCommitmentsForCurrentUser />},
        { path: '/get-accredited*', element: <GetAccredited />},
        { path: '/entities/:entityId',
          children: [
            { path: '/entities/:entityId', element: <Navigate to="offers" /> },
            { path: '/entities/:entityId/offers', element: <Offerings /> },
            { path: '/entities/:entityId/offers/:offerId', element: <Offering /> },
            { path: '/entities/:entityId/stakeholders', element: <Stakeholders /> },
          ]
        },
      ]
    },
  ];

  const GUEST_ROUTES = [
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to="/login" replace /> },
        {
          path: 'login*',
          element: <Login />
        },
        {
          path: 'register',
          element: <Register />
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'confirm-reset-password', element: <ConfirmResetPassword /> },
        { path: 'verify-your-email', element: <VerifyYourEmail /> },
        { path: 'accept-invitation', element: <AcceptInvitation /> },
        {
          path: 'invites/:inviteId',
          element: <Navigate to={`/login?nextPath=${window.location.pathname}`} replace />,
        }
      ]
    },
  ];

  let routes = COMMON_ROUTES;

  if (isAuthenticated) {
    routes = routes.concat(USER_ROUTES);
  } else {
    routes = routes.concat(GUEST_ROUTES);
  }

  return useRoutes(routes);
}
// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ConfirmResetPassword = Loadable(lazy(() => import('../views/auth/ConfirmResetPassword')));
const AcceptInvitation = Loadable(lazy(() => import('../views/auth/AcceptInvitation')));
const VerifyYourEmail = Loadable(lazy(() => import('../views/auth/VerifyYourEmail')));

// Admin
const Offerings = Loadable(lazy(() => import('../views/admin-experience/Offerings')));
const Discover = Loadable(lazy(() => import('../views/admin-experience/Stakeholders')));
const ViewEngagement = Loadable(lazy(() => import('../views/admin-experience/Stakeholders')));
const Settings = Loadable(lazy(() => import('../views/admin-experience/Settings')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
